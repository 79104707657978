import { useQuery } from 'react-query';

import { ALL_BENEFITS } from '../../../constans/queryKeys';
import { listBenefits } from '../../../services/traveller';

export function useBenefits() {
    const {
        data: benefits,
    } = useQuery([ALL_BENEFITS], listBenefits, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { benefits };
}
