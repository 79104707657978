import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Typography } from '@mui/material';

import {
    getBookingStatusKeyName,
    getStatusButtonColor,
    getStatusHoverColor,
    getStatusTextColor,
} from '../../../../helpers/bookingStatusUtil';

const useStyles = makeStyles({
    statusButton: {
        lineHeight: '0.5rem',
        padding: '0.25rem 0.75rem 0.25rem 0.75rem!important',
        width: '100%'
    },
    withDropdownButton: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
});

interface StatusButtonProps {
    onClick?: MouseEventHandler;
    variant?: string;
    children: string;
    className?: string;
    showDropdownButton?: boolean;
    disablePulseEffect?: boolean;
}

const StatusButton = ({
    onClick = () => {},
    variant = 'Pending',
    children,
    className,
    showDropdownButton,
    disablePulseEffect = true,
}: StatusButtonProps) => {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            onClick={onClick}
            disableRipple={disablePulseEffect}
            sx={{
                backgroundColor: getStatusButtonColor(
                    getBookingStatusKeyName(variant),
                ),
                color: getStatusTextColor(getBookingStatusKeyName(variant)),
                '&:hover': {
                    backgroundColor: getStatusHoverColor(
                        getBookingStatusKeyName(variant),
                    ),
                    cursor: 'unset'
                },
            }}
            className={clsx(
                className,
                classes.statusButton,
                showDropdownButton && classes.withDropdownButton,
            )}
        >
            <Typography sx={{fontSize: '0.8rem !important'}}>{children}</Typography>{' '}
            {showDropdownButton && <ArrowDropDownIcon sx={{ marginLeft: '1rem' }} />}
        </Button>
    );
};

export default StatusButton;
