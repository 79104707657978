import { Validated } from '../Validator';

const isFilledObject = <TValue, TError>(
    value: TValue | null,
    error: TError,
): Validated<TValue, TError> => (
    value === null || Object.keys(value).length === 0
        ? {
            isValid: false,
            error,
        }
        : {
            isValid: true,
            value,
        }
);

export default isFilledObject;
