import { useState } from 'react';
import { PrimaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { AxiosError } from 'axios';

import PageTitle from '../../../components/PageTitle';
import InputError from '../../../helpers/validation/InputError';
import getHelperText from '../../../helpers/validation/getHelperText';
import { logoIcon } from '../../../images';
import allValid from '../../../helpers/validation/allValid';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { resetPassword } from '../../../services/users';
import useUrlQuery from '../../../helpers/useUrlQuery';
import isPasswordStrong from '../../../helpers/validation/validators/isPasswordStrong';
import isEqualString from '../../../helpers/validation/validators/isEqualString';
import { SIGN_IN } from '../../../constans/urlPaths';
import ShowPasswordButton from '../../../components/ShowPasswordButton';

const useStyles = makeStyles({
    container: {
        minHeight: '100%',
    },
    formField: {
        marginBottom: '2rem',
    },
    buttons: {
        width: '100%',
    },
    signInButton: {
        width: '100%',
        marginTop: '2rem',
    },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
        marginBottom: '2rem',
    },
    helpText: {
        fontSize: '0.75rem',
    },
});

const TOKEN_QUERY_PARAMETER = 'token';
const EMAIL_QUERY_PARAMETER = 'email';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<InputError | null>(
        null,
    );
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { showNotification } = useNotificationContext();
    const urlParams = useUrlQuery();
    const navigate = useNavigate();

    const token = urlParams.get(TOKEN_QUERY_PARAMETER) ?? '';
    const email = urlParams.get(EMAIL_QUERY_PARAMETER) ?? '';

    const { mutate: resetPasswordMutation, isLoading } = useMutation(resetPassword, {
        onSuccess: () => {
            showNotification('Password successfully updated.', Severity.Info);
            navigate(SIGN_IN);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(
                error?.response?.data?.message || t('errors.retry'),
                Severity.Error,
            );
        },
    });

    const validateFormFields = (): boolean => {
        setPasswordError(null);
        setConfirmPasswordError(null);

        const validated = {
            password: isPasswordStrong(password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(confirmPassword, password, InputError.NoMatch),
        };

        if (!allValid(validated)) {
            setPasswordError(
                validated.password.isValid ? null : validated.password.error,
            );
            setConfirmPasswordError(
                validated.confirmPassword.isValid
                    ? null
                    : validated.confirmPassword.error,
            );

            return false;
        }

        return true;
    };

    const handleUpdate = () => {
        if (validateFormFields()) {
            resetPasswordMutation({
                password,
                password_confirmation: confirmPassword,
                token,
                email,
            });
        }
    };

    return (
        <SignedOutLayout>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
            >
                <Card variant="outlined" sx={{ width: isMobile ? '100%' : '500px' }}>
                    <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <img
                                src={logoIcon}
                                alt={'Logo'}
                                className={classes.logoContainer}
                            />
                        </Grid>
                        <PageTitle title="Reset your password" />
                        <Typography sx={{ marginBottom: '2rem' }}>
                            Please enter your new password
                        </Typography>
                        <TextField
                            id={uuid()}
                            label="Password"
                            type={showingPassword ? 'text' : 'password'}
                            value={password}
                            onChange={event => {
                                setPassword(event.target.value);
                                setPasswordError(null);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <ShowPasswordButton
                                        onShowPassword={password => {
                                            setShowingPassword(password);
                                        }}
                                    />
                                ),
                            }}
                            error={passwordError !== null}
                            helperText={getHelperText(passwordError, t)}
                            required
                            className={classes.formField}
                        />

                        <TextField
                            id={uuid()}
                            label="Confirm password"
                            type={showingConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={event => {
                                setConfirmPassword(event.target.value);
                                setConfirmPasswordError(null);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <ShowPasswordButton
                                        onShowPassword={password => {
                                            setShowingConfirmPassword(password);
                                        }}
                                    />
                                ),
                            }}
                            error={confirmPasswordError !== null}
                            helperText={getHelperText(confirmPasswordError, t)}
                            required
                            className={classes.formField}
                        />
                        <input name="token" type="hidden" value={token} />
                        <input name="email" type="hidden" value={email} />
                        <Typography
                            variant="body1"
                            mb={'2em'}
                            className={classes.helpText}
                        >
                            Use 8 or more characters with a mix of letters, numbers &
                            symbols
                        </Typography>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <PrimaryButton
                                onClick={handleUpdate}
                                className={classes.buttons}
                                loading={isLoading}
                            >
                                Update
                            </PrimaryButton>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </SignedOutLayout>
    );
};

export default ResetPassword;
