function roundToNearest10(value: number, precision: number) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const getRoundedDistance = (distance: number) => {
    if (distance < 1) return roundToNearest10(distance * 1000, -1) + ' m';
    else if (distance < 20) return Math.round(distance * 10) / 10 + ' km';
    else return Math.round(distance) + ' km';
};
