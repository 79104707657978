enum InputError {
    Required,
    InvalidEmail,
    NoMatch,
    InvalidPassword,
    NotStrongPassword,
    InvalidPhoneNumber,
    InvalidAfterDate,
    InvalidPastDate,
    EmptyEmail,
    InvalidAfterTime,
}

export default InputError;
