import apiClient from './api';
import {
    CustomerRequest,
    CustomerResponse,
    CustomerRoleResponse,
} from '../pages/accounts/api/types';
import { CREATE_CUSTOMER, CUSTOMER, getCustomerRolesRoute, getCustomers } from './routes';

export const listCustomers = async (search?: string): Promise<CustomerResponse[]> => {
    const { data } = await apiClient.get<CustomerResponse[]>(getCustomers(search));

    return data;
};

export const viewCustomer = async (id: number): Promise<CustomerResponse> => {
    const { data } = await apiClient.get<CustomerResponse>(`${CUSTOMER}/${id}`);

    return data;
};

export const createCustomer = async (
    variables: Partial<CustomerRequest>,
): Promise<any> => {
    const { name, address } = variables;
    const response = await apiClient.post<CustomerResponse>(CREATE_CUSTOMER, {
        name,
        address,
    });

    return response;
};

export const updateCustomer = async (
    variables: Partial<CustomerRequest>,
): Promise<any> => {
    const { id, name, address } = variables;
    const response = await apiClient.put(`${CUSTOMER}/${id}`, {
        name,
        address,
    });

    return response;
};

export const listCustomerRoles = async (id: number): Promise<CustomerRoleResponse[]> => {
    const { data } = await apiClient.get<CustomerRoleResponse[]>(
        getCustomerRolesRoute(id),
    );

    return data;
};
