import { Grid, Skeleton, Card, CardContent } from '@mui/material';
import React from 'react';

const InvoiceLoadingSkeleton = () => {
    return (
        <Grid container spacing={4} marginTop="2rem">
            {/* Left Section */}
            <Grid item md={6}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Skeleton variant="text" height={30} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="text" width="60%" height={30} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rectangular" width="25%" height={50} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

               
                <Grid item xs={12} marginTop={4}>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Skeleton variant="text" width="50%" height={30} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rectangular" height={50} width="100%" />
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={40} width="100%" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={40} width="100%" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Skeleton variant="text" width="50%" height={30} /> 
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={50} width="100%" /> 
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={50} width="100%" /> 
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={50} width="100%" />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={100} width="100%" />
                            </Grid>
                        </Grid>
                        <Grid container direction="column" spacing={2}>
                            <Grid item marginTop={4}>
                                <Skeleton variant="text" width="50%" height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={50} width="100%" /> 
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" height={40} width="100%" /> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" height={40} width="100%" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" spacing={2}>
                            <Grid item marginTop={4}>
                                <Skeleton variant="text" width="50%" height={30} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rectangular" height={50} width="100%" /> 
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" height={40} width="100%" /> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton variant="rectangular" height={40} width="100%" /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            {/* Update Button */}
            <Grid item xs={12} display="flex" justifyContent="flex-start" marginTop="1rem" columnGap={4}>
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
            </Grid>
        </Grid>
    );
};

export default InvoiceLoadingSkeleton;
