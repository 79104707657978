import React from 'react';
import { LabeledData } from '@get-e/react-components';
import DownloadIcon from '@mui/icons-material/Download';

import { COLORS } from '../../../constans/colors';
import { Statement } from '../../billing/api/types';
import { Box, Grid, IconButton } from '@mui/material';
import { getFormattedDatePeriod } from '../uploadInvoice/helpers';

interface BookingsDetailsProps {
    hotelName: string;
    relationName: string;
    statementPeriod: Statement;
    handleDownloadStatement: () => void;
 }

const BookingsDetails= ({ hotelName, relationName, statementPeriod, handleDownloadStatement }: BookingsDetailsProps) => {

    return (
        <Grid item xs={12}>
            <LabeledData
                label="Hotel name:"
                data={hotelName}
                labelColor={COLORS.BLUE}
                ignoreColumns
                paddingBottom=".5rem"
            />
            <LabeledData
                label="Relation name:"
                data={relationName}
                labelColor={COLORS.BLUE}
                ignoreColumns
                paddingBottom='0'
            />
            <Box sx={{display: 'flex', alignItems: "center"}}>
                <LabeledData
                    label="Statement period:"
                    data={getFormattedDatePeriod(statementPeriod)}
                    labelColor={COLORS.BLUE}
                    labelColumnNumber={5}
                    paddingBottom='0'
                />
                <IconButton href="" target="_blank" 
                    onClick={handleDownloadStatement} sx={{ marginLeft: '1rem'}}>
                    <DownloadIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />
                </IconButton>
            </Box>
        </Grid>
    );
};

export default BookingsDetails;
