import { makeStyles } from '@mui/styles';

import { COLORS } from '../../../constans/colors';

export const useDataGridStyles = makeStyles({
    headerTitle: {
        fontWeight: '600',
    },
    scrollContent: {
        maxHeight: 1000,
    },
    root: {
        border: 'none',
        borderRadius: '0px 0px 4px 4px',
    },
    dataGrid: {
        marginTop: '32px',

        '& .MuiDataGrid-cellContent': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
        },
    },
    hideRightSeparator: {
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
        },
    },
    dataGridNoRows: {
        '& .MuiDataGrid-root': {
            minHeight: '330px !important',
        },
    },
    noResultsWrapper: {
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
        '& > img': {
            width: '120px !important',
            height: '70px !important',
            marginBottom: '0.75rem',
        },
    },
});
