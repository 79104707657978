import apiClient from './api';
import { User } from '../pages/people/api/types';
import {
    getActiveAndDeactivatedHotelUsersRoute,
    getInvitedHotelUsersRoute,
    getRevokeHotelUserInvitationRoute,
} from './routes';

export const listInvitedUsers = async (id: number): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(getInvitedHotelUsersRoute(id));

    return data;
};

export const listUsers = async (id: number): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(
        getActiveAndDeactivatedHotelUsersRoute(id),
    );

    return data;
};

export const revokeInvitedUser = async (hotelId: number, userInviteId: number) => {
    const { data } = await apiClient.delete(
        getRevokeHotelUserInvitationRoute(hotelId, userInviteId),
    );

    return data;
};
