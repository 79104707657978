import { StatementVariables } from "../billing/api/types";

export enum ChangeStatus {
    WITHDRAW = 'WITHDRAW',
    DOWNLOAD_FILES = 'DOWNLOAD_FILES',
    DOWNLOAD_INVOICES = 'DOWNLOAD_INVOICES',
    DOWNLOAD_STATEMENTS = 'DOWNLOAD_STATEMENTS'
}

export enum StatusOption {
    MATCHED = 'MATCHED',
    NO_MATCHED = 'NO_MATCH'
}

export interface InvoiceVariables extends StatementVariables { 
    status: StatusOption | '';
}  