export const LABELS = {
    checkInDateZulu: 'Check-in (Zulu)',
    checkOutDateZulu: 'Check-out (Zulu)',
    checkInDateLocal: 'Check-in (Local)',
    checkOutDateLocal: 'Check-out (Local)',
    timeZone: 'Time Zone',
    status: 'Status',
    bookingNumber: 'Confirmation Number',
    notes: 'Special Request',
    hotelComments: 'Hotel Comments',
    benefits: 'Benefits',
    isTimeIncluded: 'Is Time Included',
    estimatedArrivalTime: 'Estimated Arrival Time',
    bookerEmail: 'Booker Email',

    customer_id: 'Customer Id',
    customer_name: 'Customer Name',
    customer_createdAt: 'Customer Created At',
    customer_updatedAt: 'Customer Updated At',

    hotel_id: 'Hotel Id',
    hotel_name: 'Hotel Name',
    hotel_email: 'Hotel Email',
    hotel_phoneNumber: 'Hotel Phone Number',
    hotel_notes: 'Hotel Notes',
    hotel_standardCheckIn: 'Hotel Standard Check-in',
    hotel_standardCheckOut: 'Hotel Standard Check-out',
    hotel_address: 'Hotel Address',

    guest_id: 'Guest Id',
    guest_travellerId: 'Traveller Id',
    guest_firstName: 'Guest First Name',
    guest_lastName: 'Guest Last Name',
    guest_email: 'Guest Email',
    guest_phoneNumber: 'Guest Phone Number',
    guest_employeeNumber: 'Guest Employee Number',

    contract_type: 'Contract type',
    contract_id: 'Contract Id',
    contract_customerId: 'Contract Customer Id',
    contract_hotelId: 'Contract Hotel Id',
    contract_currency: 'Contract Currency',
    contract_priceIncludingVat: 'Contract Price Including VAT',
    contract_commissionPercentage: 'Contract Commission Percentage',
    contract_checkIn: 'Contract Extended Check-in',
    contract_checkOut: 'Contract Extended Check-out',
    contract_createdAt: 'Contract Created At',
    contract_updatedAt: 'Contract Updated At',

    commissionContract: 'Commission Contract',
    commissionContract_id: 'Commission Contract Id',
    commissionContract_customerId: 'Commission Contract Customer Id',
    commissionContract_hotelId: 'Commission Contract Hotel Id',
    commissionContract_currency: 'Commission Contract Currency',
    commissionContract_priceIncludingVat: 'Commission Contract Price Including VAT',
    commissionContract_commissionPercentage: 'Commission Contract Commission Percentage',
    commissionContract_checkIn: 'Contract Extended Check-in',
    commissionContract_checkOut: 'Contract Extended Check-out',
    commissionContract_createdAt: 'Commission Contract Created At',
    commissionContract_updatedAt: 'Commission Contract Updated At',

    feeContract: 'Fee Contract',
    feeContract_id: 'Fee Contract Id',
    feeContract_customerId: 'Fee Contract Customer Id',
    feeContract_hotelId: 'Fee Contract Hotel Id',
    feeContract_currency: 'Fee Contract Currency',
    feeContract_feePerUnit: 'Contract Fee Per Unit',
    feeContract_hotelCostPerUnit: 'Fee Contract Hotel Cost Per Unit',
    feeContract_checkIn: 'Contract Extended Check-in',
    feeContract_checkOut: 'Contract Extended Check-out',
    feeContract_createdAt: 'Fee Contract Created At',
    feeContract_updatedAt: 'Fee Contract Updated At',

    transactions: 'Transactions',

    BOOKING_CONFIRMED: 'Confirmed',
    PROCESS_CANCELLATION: 'Cancelled',
    BOOKING_CANCELLED: 'Confirmed Cancellation',
    COMPLETED: 'Completed',
    BOOKING_PENDING: 'Updated',
    SEARCHING_ROOMS: 'Updated',
    BOOKING_UPDATE_PENDING: 'Updated',
    NOT_AVAILABLE: 'Updated',
};
