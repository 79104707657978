import { Typography } from '@mui/material';
import SimpleLink from '../../components/SimpleLink';

import { LOCAL_STORAGE_KEYS } from '../../constans/localStorageKeys';
import { BREADCRUMB_DIVIDER } from '../../constans/placeholders';
import {
    ACCOUNTS_CUSTOMERS,
    ACCOUNTS_HOTELS,
    CREATE_CUSTOMER,
    CREATE_HOTEL,
    EDIT_CUSTOMER,
    EDIT_HOTEL,
} from '../../constans/urlPaths';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import PermissionsGate from '../../permissions/PermissionsGate';
import AccountsContent from './accountsContent/AccountsContent';
import CreateAccount from './createAccount/CreateCustomer';
import CreateHotel from './createAccount/CreateHotel';
import EditAccount from './editAccount/EditCustomer';
import EditHotel from './editAccount/EditHotel';

export enum AccountsTabs {
    Hotels,
    Customers,
}

export const customersAccounts: PageDefinition = {
    path: ACCOUNTS_CUSTOMERS,
    Component() {
        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}Customers
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <AccountsContent tab={AccountsTabs.Customers} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const hotelsAccounts: PageDefinition = {
    path: ACCOUNTS_HOTELS,
    Component() {
        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_HOTELS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}Hotels
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <AccountsContent tab={AccountsTabs.Hotels} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const createCustomer: PageDefinition = {
    path: CREATE_CUSTOMER,
    Component() {
        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}Create customer
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <CreateAccount />
                </Page>
            </PermissionsGate>
        );
    },
};

export const createHotel: PageDefinition = {
    path: CREATE_HOTEL,
    Component() {
        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_HOTELS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}Create hotel
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <CreateHotel />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editAccount: PageDefinition = {
    path: EDIT_CUSTOMER,
    Component() {
        const accountName =
            window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_EDITED) ?? '';

        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {accountName}
                                    {BREADCRUMB_DIVIDER}Edit customer
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditAccount />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editHotel: PageDefinition = {
    path: EDIT_HOTEL,
    Component() {
        const accountName =
            window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_EDITED) ?? '';

        return (
            <PermissionsGate>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_HOTELS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {accountName}
                                    {BREADCRUMB_DIVIDER}Edit hotel
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditHotel />
                </Page>
            </PermissionsGate>
        );
    },
};
