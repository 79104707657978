import { useEffect } from 'react';
import { DataGrid } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { getColumns } from '../gridData';
import { useAuth } from '../../../../context/AuthenticatedUserContext';
import { useDataGridStyles } from '../styles/useDataGridStyles';
import { emptyAirline } from '../../../../images';
import { EDIT_PEOPLE_BASE_URL } from '../../../../constans/urlPaths';
import { LOCAL_STORAGE_KEYS } from '../../../../constans/localStorageKeys';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { useDeactivated } from '../../api/useDeactivatedHotelUsers';
import { mapUserToTableRow } from '../../helpers/tableMapper';
import { BUTTON_EDIT_USER } from '../../../../amplitude/amplKeys';

interface DeactivatedProps {
    onUpdate: (deactivated: number) => void;
    isActive: boolean;
}

const DeactivatedPanel = ({ onUpdate, isActive }: DeactivatedProps) => {
    const dataGridClasses = useDataGridStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { user } = useAuth();

    const hotelId = (id && parseInt(id)) || (user?.hotel.id ?? -1);

    const { deactivatedUsersData = [], isFetching: isFetchingDeactivated } =
        useDeactivated(hotelId);

    useEffect(() => {
        onUpdate?.(deactivatedUsersData?.length ?? 0);
    }, [deactivatedUsersData?.length, onUpdate]);

    const rows = deactivatedUsersData?.length ? mapUserToTableRow(deactivatedUsersData) : [];

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_EDITED,
            params.row.name ?? '',
        );
        navigate(`${EDIT_PEOPLE_BASE_URL}${params.id}`);
        logAmplitudeEvent(BUTTON_EDIT_USER)
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <>
                    <DataGrid
                        className={clsx(
                            dataGridClasses.dataGrid,
                            !rows.length ? dataGridClasses.dataGridNoRows : '',
                        )}
                        rows={isFetchingDeactivated ? [] : rows}
                        columns={getColumns()}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        autoHeight
                        noRows="No result found"
                        loading={isFetchingDeactivated}
                        getRowHeight={() => (isMobile ? 'auto' : null)}
                        noResultImage={emptyAirline}
                        onRowClick={handleRowClick}
                        noRowsClassName={dataGridClasses.noResultsWrapper}
                        hideFooter={!rows.length}
                    />
                </>
            )}
        </div>
    );
};

export default DeactivatedPanel;
