import Cookies from 'js-cookie';
import axios from 'axios';

import {
    STATUS_401,
    STATUS_403,
    STATUS_429,
} from '../constans/httpStatusCodes';
import {
    ERROR_NOT_AUTHORIZED,
    ERROR_TOO_MANY_REQUESTS,
    SIGN_IN,
} from '../constans/urlPaths';
import getEnv from '../helpers/getEnv';
import { LOCAL_STORAGE_KEYS } from '../constans/localStorageKeys';

const apiClient = axios.create({
    baseURL: getEnv('API_URL'),
    withCredentials: true,
    headers: {
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') as string,
    },
});

apiClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === STATUS_401) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER)
            window.location.href = SIGN_IN;
        } else if (error.response.status === STATUS_429) {
            window.location.href = ERROR_TOO_MANY_REQUESTS;
        } else if (error.response.status === STATUS_403) {
            window.location.href = ERROR_NOT_AUTHORIZED;
        } else {
            return Promise.reject(error);
        }
    },
);

export default apiClient;
