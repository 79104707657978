import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LocalParkingOutlinedIcon from '@mui/icons-material/LocalParkingOutlined';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import { useNavigate } from 'react-router-dom';

import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import {
    getDaysMonthRangeString,
    getFormattedDateTimeString,
} from '../../../../helpers/dateUtils';
import { TIME_FORMATS } from '../../../../helpers/timeFormats';
import { Benefits, Booking } from '../../api/types';
import StatusButton from '../StatusButton';
import { useMemo } from 'react';
import { PrimaryButton } from '@get-e/react-components';
import { EDIT_BOOKING_BASE_URL } from '../../../../constans/urlPaths';
import PermissionsGate from '../../../../permissions/PermissionsGate';
import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles({
    header: {
        display: 'flex',
    },
    map: {
        height: '220px',
        padding: '0 !important',
    },
    reservationStatus: {
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    editButton: {
        width: '120px',
    },
});

interface BookingCardProps {
    reservation: Booking;
}

const BookingCard = ({ reservation }: BookingCardProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const includeBreakfast = useMemo(
        () =>
            reservation.benefits
                ?.map(benefit => benefit.name)
                .includes(Benefits.INCLUDE_BREAKFAST)
                ? 'Included'
                : 'Not included',
        [reservation.benefits],
    );

    const includeParking = useMemo(
        () =>
            reservation.benefits
                ?.map(benefit => benefit.name)
                .includes(Benefits.INCLUDE_PARKING)
                ? 'Included'
                : 'Not included',
        [reservation.benefits],
    );

    return (
        <Card
            variant="outlined"
            sx={{
                backgroundColor: COLORS.LIGHT_GRAY,
                marginBottom: '2rem',
                border: 'none',
            }}
        >
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                color: COLORS.BLUE,
                                fontWeight: 'bold',
                            }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Booking
                        </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                        <StatusButton
                            className={classes.reservationStatus}
                            variant={reservation.status}
                        >
                            {reservation.status}
                        </StatusButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                color: COLORS.BLACK,
                                fontWeight: 'bold',
                            }}
                        >
                            {`${getDaysMonthRangeString(
                                reservation?.checkInDateLocal,
                                reservation?.checkOutDateLocal,
                            )}`}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                color: COLORS.BLACK,
                                fontWeight: 'bold',
                            }}
                            gutterBottom
                        >
                            {reservation.bookingNumber &&
                                `Hotel confirmation number: ${reservation.bookingNumber}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                    {reservation.hotelName}
                </Typography>
                <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                    {`${getFormattedDateTimeString(
                        reservation?.estimatedArrivalTime ?? '',
                        DATE_FORMATS['DD MMM YYYY'],
                        TIME_FORMATS['HH:mm A'],
                        false,
                    )}`}
                </Typography>
                <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                    {`Check in ${getFormattedDateTimeString(
                        reservation?.checkInDateLocal ?? '',
                        DATE_FORMATS['DD MMM YYYY'],
                        TIME_FORMATS['HH:mm A'],
                        reservation.isTimeIncluded,
                    )}`}
                </Typography>
                <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                    {`Check out ${getFormattedDateTimeString(
                        reservation?.checkOutDateLocal ?? '',
                        DATE_FORMATS['DD MMM YYYY'],
                        TIME_FORMATS['HH:mm A'],
                        reservation.isTimeIncluded,
                    )}`}
                </Typography>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={1} sx={{ paddingTop: '0' }}>
                        <LocalParkingOutlinedIcon fontSize="inherit" />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                    >
                        <Typography
                            sx={{ fontSize: '1rem' }}
                            color="text.secondary"
                            gutterBottom
                        >
                            {includeParking}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ paddingTop: '0' }}>
                        <LocalCafeOutlinedIcon fontSize="inherit" />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                    >
                        <Typography
                            sx={{ fontSize: '1rem' }}
                            color="text.secondary"
                            gutterBottom
                        >
                            {includeBreakfast}
                        </Typography>
                    </Grid>
                    <PermissionsGate>
                        <Grid item xs={12}>
                            <PrimaryButton
                                className={classes.editButton}
                                onClick={() => {
                                    navigate(
                                        `${EDIT_BOOKING_BASE_URL}${reservation.id}`,
                                        {
                                            state: {
                                                bookingNumber: reservation.bookingNumber,
                                            },
                                        },
                                    );
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        </Grid>
                    </PermissionsGate>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BookingCard;
