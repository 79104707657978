import {Download, Delete} from '@mui/icons-material';
import { GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { DropdownMenu } from '@get-e/react-components';

import { COLORS } from '../../../constans/colors';
import useDataGridStyles from '../../../styles/DataGrid';
import { ChangeStatus } from '../types';
import { StatusButton } from '../components/Status';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { Invoice } from '../api/types';

const COLUMN_WIDTH = 200;

export const useInvoicesColumns = (
    handleDownload: (id: number) => void,
    handleDelete: (id: number) => void,
) => {
    const dataGridClases = useDataGridStyles();

    const onActionItemClick = useCallback(
        (inovice: Invoice, action: ChangeStatus) => {
            switch (action) {
                case ChangeStatus.DOWNLOAD_FILES:
                    handleDownload(inovice.id)
                    break;
                case ChangeStatus.WITHDRAW:
                    handleDelete(inovice.id);
                    break;
                default:
                    throw new Error('Driver action not supported');
            }
        },
        []
    );

    return useMemo(
        () => [
            { field: 'invoiceReference', headerName: 'Invoice reference', minWidth: COLUMN_WIDTH, flex: 1 },
            {   
                field: 'dateRange', headerName: 'Date range', minWidth: 250,    
                renderCell: (params: GridRowParams) => {
                const isSameDate = params.row.startDateLocal === params.row.endDateLocal;
                const formattedStartDate = moment(params.row.startDateLocal).format(DATE_FORMATS['DD MMM YYYY']);
                const formattedEndDate = moment(params.row.endDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

                return (
                    <Box>
                        {isSameDate && (
                            <Typography sx={{ color: COLORS.SLATE_GREY, fontSize: '12px' }}>
                                {params.row.bookingNumber}
                            </Typography>
                        )}
                        <Typography>
                            {isSameDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`}
                        </Typography>
                    </Box>
                )}},
                { 
                    field: 'customerName', headerName: 'Hotel’s account name', width: 200
                 },
                 { 
                    field: 'hotelName', headerName: 'Customer’s account name', width: 200
                 },
                  { field: 'invoiceDateLocal', headerName: 'Invoice date', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'dueDateLocal', headerName: 'Due date', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'totalAmount', headerName: 'Invoice Amount', minWidth: 140, flex: 1,  
                renderCell: (params: GridRowParams) => {
                return <>{params.row.currencyIso} {params.row.totalAmount}</>
            }   },
            { field: 'totalRoomNights', headerName: 'Invoice Room nights', minWidth: 140, flex: 1  },
            { field: 'statementTotalAmount', headerName: 'Statement Amount',   
                minWidth: 200,
               flex: 1,
               renderCell: (params: GridRowParams) => {
                   return <>{params.row.statement?.currencyIso} {params.row.statement?.totalAmount}</>
               } 
            },
            { field: 'statementTotalRoomNights', headerName: 'Statement room nights', width: 180, 
            renderCell: (params: GridRowParams) => {
                return <>{params.row.statement?.totalRoomNights}</>
            } 
            },
       
            {
              field: 'status',
              headerName: 'Status',
              width: 130,
              renderCell: (params: GridRenderCellParams) => (
                <StatusButton status={params.row.status}/>
              ),
            },
            {
                field: " ",
                headerClassName: dataGridClases.hideSeparator,
                width: 20,
                renderCell: (params: GridRenderCellParams) => {
                    const invoice = params.row as any;
                    const actionItems = [];
                    actionItems.push({
                        id: 1,
                        label:'Download files',
                        icon: Download,
                        iconColor: COLORS.BLUE_DARK,
                        labelColor: COLORS.BLUE_DARK,
                        onClick: () =>  onActionItemClick(invoice, ChangeStatus.DOWNLOAD_FILES),
                    }, {
                        id: 2,
                            icon: Delete,
                            iconColor: COLORS.RED,
                            labelColor: COLORS.RED,
                            label: 'Withdraw invoice',
                            onClick: () =>onActionItemClick(invoice, ChangeStatus.WITHDRAW),
                      
                    })
                    
                    return( <DropdownMenu options={actionItems}/>)
                },
            },
        ],
        []
    );
};
