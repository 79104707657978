import {
    PaginationType,
    BookingVariables,
    Request,
    SearchResponse,
    CreateRequest,
    EditRequest,
} from '../pages/reservations/api/types';
import apiClient from './api';
import { CANCEL_REQUEST, CREATE_REQUEST, REQUESTS, REQUESTS_SEARCH } from './routes';

export interface RequestResponse {
    data: Request[];
    meta: PaginationType;
}

export const listRequests = async (
    variables: Partial<BookingVariables>,
): Promise<RequestResponse> => {
    const { page = 1, activeTab, customerId, searchType, searchValue } = variables;

    const searchParams = new URLSearchParams({
        page: page.toString(),
        activeTab: activeTab?.toString() ?? '',
        customerId: customerId?.toString() ?? '',
        searchType: searchType ?? '',
        searchValue: searchValue ?? '',
    }).toString();

    const { data } = await apiClient.get<RequestResponse>(`${REQUESTS}?${searchParams}`);

    return data;
};

export const createRequest = async (variables: Partial<CreateRequest>): Promise<any> => {
    const response = await apiClient.post(CREATE_REQUEST, variables);

    return response;
};

export const searchRequests = async (search: string): Promise<SearchResponse> => {
    const { data } = await apiClient.get<SearchResponse>(`${REQUESTS_SEARCH}/${search}`);

    return data;
};

export const viewRequest = async (id: number): Promise<Request> => {
    const { data } = await apiClient.get<Request>(`${REQUESTS}/${id}`);

    return data;
};

export const updateRequest = async (variables: Partial<EditRequest>): Promise<any> => {
    const { id } = variables;
    const response = await apiClient.put(`${REQUESTS}/${id}`, variables);

    return response;
};

export const cancelRequest = async (id: number): Promise<any> => {
    const response = await apiClient.patch(`${CANCEL_REQUEST}/${id}`);

    return response;
};
