import ReactDOM from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import chameleon from '@chamaeleonidae/chmln';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

(async () => {
    const initialLdContext = {
        kind: 'user',
        key: 'test@get-e.com',
        name: 'get-e',
        email: 'test@get-e.com',
    };

    if (window.location.hostname.includes('dev')) {
        chameleon.init('SUwKvhx3SxwlHMfJWSA5y00gzqd8tBCbSdRkvDQyM88tbx-1SGoRc-Fmz1SSd4kxC9Ws30', {
            fastUrl: 'https://fast.chameleon.io/',
        });
    }
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
        context: initialLdContext,
    });

    root.render(
        <LDProvider>
            <App />
        </LDProvider>
    );
})();