import { ReactElement, useEffect, useMemo } from 'react';
import {
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { FullScreenLoader } from '@get-e/react-components';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import chameleon from '@chamaeleonidae/chmln';

import createRoutes from './createRoutes';
import AcceptInvite from './pages/people/acceptInvite/AcceptInvite';
import ForgotPassword from './pages/password/forgotPassword/ForgotPassword';
import SignIn from './pages/signIn/SignIn';
import {
    ACCEPT_INVITE,
    FORGOT_PASSWORD,
    SIGN_IN,
    HOME,
    RESET_PASSWORD,
    ERROR_TOO_MANY_REQUESTS,
    ERROR_NOT_AUTHORIZED,
    BOOKINGS,
} from './constans/urlPaths';
import ResetPassword from './pages/password/resetPassword/ResetPassword';
import ErrorNotFoundPage from './pages/errorNotFoundPage/ErrorNotFoundPage';
import { useAuth } from './context/AuthenticatedUserContext';
import ErrorTooManyRequestPage from './pages/errorTooManyRequestPage/ErrorTooManyRequestPage';
import ErrorNotAuthorizedPage from './pages/errorNotAuthorizedPage/ErrorNotAuthorizedPage';

import { setAmplitudeUserId, setAmplitudeUserProperties } from './amplitude/amplitude';

const InitialRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(SIGN_IN);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FullScreenLoader />;
};

interface ProtectedRouteProps {
    children?: ReactElement;
    redirectPath?: string;
    user?: unknown;
}

const ProtectedRoute = ({ children, redirectPath = SIGN_IN }: ProtectedRouteProps) => {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.user) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return children ?? <Outlet />;
};

interface PublicOnlyProps {
    children?: ReactElement;
    redirectPath?: string;
}

export const PublicOnlyRoute = ({
    redirectPath = BOOKINGS,
    children,
}: PublicOnlyProps) => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.user) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return children ?? <Outlet />;
};

const PortalRoutes = () => {
    const routes = useMemo(() => {
        const routesConfigs = createRoutes();

        return routesConfigs.map(({ path, Component }) => (
            <Route path={path} element={<Component />} key={path} />
        ));
    }, []);

    const { user } = useAuth();

    // TODO: TO BE DELETED!
    // After refactoring and renaming api response from snake_case to camelCase, it is causing a problem with users who 
    // are already logged it because the user object with snake_case still exists in local storage 
    if (
        user !== null 
        && (user.isBackoffice === undefined
            || user.isActive === undefined
            || user.firstName === undefined
            || user.lastName === undefined
            || user.roles === undefined
            || user.permissions === undefined
        )
    ) {
        localStorage.clear();
    }

    const ldClient = useLDClient();

    const ldContext = {
        kind: 'user',
        key: user?.email,
        email: user?.email,
    };

    ldClient?.identify(ldContext);

    if (window.location.hostname.includes('dev')) {
        chameleon.identify(user?.id || '', {
            email: user?.email,
            name: user?.firstName + ' ' + user?.lastName,
            role: user?.roles,
        });
    }
    setAmplitudeUserId(user?.id?.toString() ?? '');
    
    const userProperties = {
        'User id': user?.id,
        Name: user?.firstName + ' ' + user?.lastName,
        Email: user?.email,
        'Is backoffice': user?.isBackoffice,
        'Customer id': user?.customer?.id,
        Permissions: user?.permissions,
    };
    setAmplitudeUserProperties(userProperties);

    return (
        <Routes>
            <Route path={RESET_PASSWORD} element={<ResetPassword />} />
            <Route
                path={HOME}
                element={
                    <PublicOnlyRoute>
                        <InitialRoute />
                    </PublicOnlyRoute>
                }
            />
            <Route
                path={FORGOT_PASSWORD}
                element={
                    <PublicOnlyRoute>
                        <ForgotPassword />
                    </PublicOnlyRoute>
                }
            />
            <Route
                path={SIGN_IN}
                element={
                    <PublicOnlyRoute>
                        <SignIn />
                    </PublicOnlyRoute>
                }
            />
            <Route
                path={ACCEPT_INVITE}
                element={
                    <PublicOnlyRoute>
                        <AcceptInvite />
                    </PublicOnlyRoute>
                }
            />
            <Route element={<ProtectedRoute />}>{routes}</Route>
            <Route path="*" element={<ErrorNotFoundPage />} />
            <Route path={ERROR_TOO_MANY_REQUESTS} element={<ErrorTooManyRequestPage />} />
            <Route path={ERROR_NOT_AUTHORIZED} element={<ErrorNotAuthorizedPage />} />
        </Routes>
    );
};

export default PortalRoutes;
