import apiClient from './api';
import {
    Booking,
    ConfirmBooking,
} from '../pages/reservations/api/types';
import { HOTEL_CONFIRM_BOOKING } from './routes';


export const confirm = async (variables: ConfirmBooking): Promise<any> => {
    const { payload } = variables;
    try {
        const response = await apiClient.patch<Booking>(`${HOTEL_CONFIRM_BOOKING}/`, payload);
        
        return response;
    } catch (error: any) {
        throw Error(error.response.data.message)
    }
};