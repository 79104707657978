
import { FunctionComponent, ReactNode, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const Refresh: FunctionComponent<PropsWithChildren<{ children: ReactNode }>> = ({
    children,
}) => {
    const classes = useStyles();

    return <div className={classes.container}>{children}</div>;
};

export default Refresh;
