/* eslint-disable react-hooks/rules-of-hooks */
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import uuid from 'react-uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useMediaQuery } from '@mui/material';
import { MenuButton } from '@get-e/react-components';
import { useState } from 'react';
import { Box } from '@mui/system';

import { useDataGridStyles } from './styles/useDataGridStyles';

const FIELDS: { [key: string]: string } = {
    NAME: 'name',
    EMAIL: 'email',
    ROLE: 'role',
    INVOKE: 'invoke',
};

const ROLE_ICON: { [key: string]: JSX.Element } = {
    Finance: <CardTravelIcon fontSize="small" />,
    Operations: <PersonOutlineOutlinedIcon fontSize="small" />,
    Admin: <AdminPanelSettingsOutlinedIcon fontSize="small" />,
};

const COLUMN_WIDTH = {
    DEFAULT: 280,
    MOBILE: 380,
};

export const getColumns = (): GridColDef[] => {
    const classes = useDataGridStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    return [
        {
            field: FIELDS.NAME,
            headerName: 'Name',
            minWidth: isMobile ? COLUMN_WIDTH.MOBILE : COLUMN_WIDTH.DEFAULT,
            headerClassName: isMobile ? classes.hideRightSeparator : '',
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    {!isMobile && <div>{params.row.name}</div>}
                    {isMobile && (
                        <div className="dataGridMobile">
                            <b>{params.row.name}</b>
                            <p>{params.row.email}</p>
                            <div className="dataGridRole">
                                {params.row.roles.map((role: string) => (
                                    <div key={uuid()}>
                                        <div className="dataGridRoleIcon">
                                            {ROLE_ICON[role]}
                                        </div>
                                        {role}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ),
        },
        {
            field: FIELDS.EMAIL,
            headerName: 'Email',
            minWidth: 300,
            hide: isMobile,
        },
        {
            field: FIELDS.ROLE,
            headerName: 'Role',
            minWidth: 400,
            headerClassName: classes.hideRightSeparator,
            hide: isMobile,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div className="dataGridRole">
                    {params.row.roles.map((role: string) => (
                        <div key={uuid()}>
                            <div className="dataGridRoleIcon">{ROLE_ICON[role]}</div>
                            {role}
                        </div>
                    ))}
                </div>
            ),
        },
    ];
}

export const getInivitedColumns = (onRevokeClicked: (id:number) => void): GridColDef[] => {
    const classes = useDataGridStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: FIELDS.NAME,
            headerName: 'Name',
            minWidth: isMobile ? COLUMN_WIDTH.MOBILE : COLUMN_WIDTH.DEFAULT,
            headerClassName: isMobile ? classes.hideRightSeparator : '',
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    {!isMobile && <div>{params.row.name}</div>}
                    {isMobile && (
                        <div className="dataGridMobile">
                            <b>{params.row.name}</b>
                            <p>{params.row.email}</p>
                            <div className="dataGridRole">
                                {params.row.roles.map((role: string) => (
                                    <div key={uuid()}>
                                        <div className="dataGridRoleIcon">
                                            {ROLE_ICON[role]}
                                        </div>
                                        {role}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ),
        },
        {
            field: FIELDS.EMAIL,
            headerName: 'Email',
            minWidth: 300,
            hide: isMobile,
        },
        {
            field: FIELDS.ROLE,
            headerName: 'Role',
            minWidth: 350,
            headerClassName: classes.hideRightSeparator,
            hide: isMobile,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div className="dataGridRole">
                    {params.row.roles.map((role: string) => (
                        <div key={uuid()}>
                            <div className="dataGridRoleIcon">{ROLE_ICON[role]}</div>
                            {role}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            field: FIELDS.INVOKE,
            headerName: '',
            renderCell: (params) =>renderDetailsButton(params, onRevokeClicked),
        },
    ];
};

const renderDetailsButton = (params: any, onRevokeClicked: (id: number) => void) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useDataGridStyles();

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    return (
        <MenuButton
            onClick={onOpen}
            open={isOpen}
            onClose={onClose}
            menuItems={
                <Box
                    onClick={() => {
                        onRevokeClicked(params.row.id);
                        onClose();
                    }}
                    component="li"
                    className={classes.revokeItem}
                >
                    Revoke invitation
                </Box>
            }
            alignItem="left"
        >
            <MoreVertIcon />
        </MenuButton>
    );
};
