export enum DATE_FORMATS {
    'DD' = 'DD',
    'MMM' = 'MMM',
    'DD MMM YYYY' = 'DD MMM YYYY',
    'dd MMM yyyy' = 'dd MMM yyyy',
    'YYYY-MM-DD' = 'YYYY-MM-DD',
    'DD MMM' = 'DD MMM',
    'DD MMM YYYY HH:mm A' = 'DD MMM YYYY HH:mm A',
    'YYYY-MM-DDTHH:mm:ss' = 'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DD HH:mm:ss' = 'YYYY-MM-DD HH:mm:ss'
}