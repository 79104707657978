import { useNavigate } from 'react-router';

import ErrorPage from '../../components/ErrorPage';
import { REQUESTS_PENDING } from '../../constans/urlPaths';
import { notFoundIcon } from '../../images';

const ErrorNotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <ErrorPage
            icon={notFoundIcon}
            header={'This page doesn’t exist or it has been removed'}
            description={
                'You can find the list of your bookings by clicking on the button below.'
            }
            buttonTitle={'Bookings'}
            onButtonClick={() => navigate(REQUESTS_PENDING)}
        />
    );
};

export default ErrorNotFoundPage;
