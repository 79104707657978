import { Typography } from "@mui/material";
import { ChangeLog } from "../../api/types";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS as MAIN_COLORS } from '../../../../constans/colors';


const useStyles = makeStyles({
    strikethroughText: {
        fontSize: '0.8rem',
        color: MAIN_COLORS.SLATE_GREY,
        textDecoration: "line-through",
        paddingLeft: '0.5rem'
    }
});

interface Props {
    fields: any[];
    version: number;
    changeLogs: ChangeLog[];
}

const CustomFields = ({fields, version, changeLogs}: Props) => {
    const classes = useStyles();

    return (
        <div>{fields.map(
                field =>  {
                    // Find previous log containing the modified custom field
                    var previousLog = changeLogs.find((c) => c.version < version 
                        && c.details.customFields !== undefined 
                            && c.details.customFields.find((customField: any) => customField.customFieldKey === 'is-training-booking')
                        )?.details.customFields.find((customField: any) => customField.customFieldKey === 'is-training-booking');

                    return <Typography>
                        <span style={{fontWeight: 'bold', fontSize: '0.9rem'}}>{field.customFieldLabel} :</span> 
                        {version > 1 && <span>
                            <span className={classes.strikethroughText}>{previousLog?.customFieldType === 'BOOLEAN' ? (previousLog?.value == '1' ? 'Yes' : 'No') : previousLog?.value}</span>
                            <span style={{paddingLeft: '0.5rem', color: MAIN_COLORS.SLATE_GREY,}}>&#8594;</span>
                        </span>}
                        <span style={{paddingLeft: '0.5rem'}}>{field.customFieldType === 'BOOLEAN' ? (field.value == '1' ? 'Yes' : 'No') : field.value}</span>
                    </Typography>
            })
        }</div>
    );
};

export default CustomFields;
