import { useQuery } from 'react-query';

import { HOTEL_ROLES } from '../../../services/routes';
import { listHotelRoles } from '../../../services/hotel';

export function useHotelRoles(id: number) {
    const { data: hotelRoles, isLoading, isError, isFetching } = useQuery([HOTEL_ROLES], () => listHotelRoles(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { hotelRoles, isLoading, isError, isFetching };
}