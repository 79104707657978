import { Loader } from '@googlemaps/js-api-loader';

import getEnv from '../getEnv';

export const DEFAULT_COORDINATES = {
    lat: 52.0842715,
    long: 5.0124521,
};

export const googleMapsKey = getEnv('GOOGLE_ADDRESS');

export const bootstrapURLKeys = {
    key: googleMapsKey,
    libraries: ['places'],
};

export const loader = new Loader({
    apiKey: googleMapsKey,
    libraries: ['places'],
});
