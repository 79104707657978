import { useQuery } from 'react-query';

import { PEOPLE } from '../../../constans/queryKeys';
import { viewUser } from '../../../services/users';

export function useUser(id: number) {
    const { data, isLoading, isError, isFetching } = useQuery(
        [PEOPLE],
        () => viewUser(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            cacheTime: 500,
        },
    );

    return { data, isLoading, isError, isFetching };
}
