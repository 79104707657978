import { BUTTON, SCREEN, INPUT, MODAL } from './eventSourceKeys';

export const BOOKINGS_LIST = `Bookings list:`;
export const SCREEN_BOOKINGS_LIST = `${SCREEN} Bookings list`;
export const BUTTON_BOOKINGS_FILTER_ALL = `${BUTTON} ${BOOKINGS_LIST} Filter by All`;
export const BUTTON_BOOKINGS_FILTER_PENDING = `${BUTTON} ${BOOKINGS_LIST} Filter by Pending`;
export const BUTTON_BOOKINGS_FILTER_UPCOMING = `${BUTTON} ${BOOKINGS_LIST} Filter by Upcoming`;
export const BUTTON_BOOKINGS_FILTER_IN_PROGRESS = `${BUTTON} ${BOOKINGS_LIST} Filter by In progress`;
export const BUTTON_BOOKINGS_FILTER_ARCHIVE = `${BUTTON} ${BOOKINGS_LIST} Filter by Archive`;
export const INPUT_BOOKINGS_SELECT_DATE_RANGE = `${INPUT} ${BOOKINGS_LIST} Select date range`;
export const INPUT_BOOKINGS_SEARCH = `${INPUT} ${BOOKINGS_LIST} Search booking`;

export const SCREEN_SINGLE_BOOKING = `${SCREEN} Single booking`;
export const BUTTON_SINGLE_BOOKING_EDIT = `${BUTTON} Single booking: Create or edit booking`;

export const CREATE_EDIT_BOOKING = `Create or edit booking:`;
export const SCREEN_CREATE_EDIT_BOOKING = `${SCREEN} Create or edit booking`;
export const BUTTON_CREATE_UPDATE_BOOKING_STATUS = `${BUTTON} ${CREATE_EDIT_BOOKING} Create or update booking status`;
export const INPUT_HOTEL_NAME = `${INPUT}${CREATE_EDIT_BOOKING} Hotel name`;
export const INPUT_BOOKING_CONFIRMATION_NUMBER = `${INPUT} ${CREATE_EDIT_BOOKING} Booking confirmation number`;
export const INPUT_CHECKINOUT_DATE = `${INPUT} ${CREATE_EDIT_BOOKING}  Select Check-in and Check-out date`;
export const INPUT_CHECKIN_TIME = `${INPUT} ${CREATE_EDIT_BOOKING} Check-in time`;
export const INPUT_CHECKOUT_TIME = `${INPUT} ${CREATE_EDIT_BOOKING} Check-out time`;
export const INPUT_SPECIAL_REQUEST = `${INPUT} ${CREATE_EDIT_BOOKING} Special request`;
export const BUTTON_INCLUDE_BREAKFAST = `${BUTTON} ${CREATE_EDIT_BOOKING} Include breakfast`;
export const BUTTON_INCLUDE_PARKING = `${BUTTON} ${CREATE_EDIT_BOOKING} Include parking`;
export const BUTTON_MAIL_TO_BOOKER_CONFIRMED = `${BUTTON} ${CREATE_EDIT_BOOKING} Mail to booker - Confirmed`;
export const BUTTON_MAIL_TO_BOOKER_UPDATED = `${BUTTON} ${CREATE_EDIT_BOOKING} Mail to booker - Updated`;
export const BUTTON_MAIL_TO_GUEST_CONFIRMED = `${BUTTON}${CREATE_EDIT_BOOKING} Mail to guest - Confirmed`;
export const BUTTON_MAIL_TO_GUEST_UPDATED = `${BUTTON} ${CREATE_EDIT_BOOKING} Mail to guest - Updated`;
export const BUTTON_SAVE_BOOKING = `${BUTTON} ${CREATE_EDIT_BOOKING} Save`;

export const CREATE_EDIT_REQUEST = `Create or edit request:`;
export const SCREEN_CREATE_EDIT_REQUEST = `${SCREEN} Create or edit request`;
export const INPUT_REQUEST_SEARCH_LOCATION = `${INPUT} ${CREATE_EDIT_REQUEST} Search location`;
export const INPUT_REQUEST_CHECKINOUT_DATE = `${INPUT} ${CREATE_EDIT_REQUEST} Check-in date`;
export const INPUT_REQUEST_CHECKIN_TIME = `${INPUT} ${CREATE_EDIT_REQUEST} Check-in time`;
export const INPUT_REQUEST_CHECKOUT_TIME = `${INPUT} ${CREATE_EDIT_REQUEST} Check-out time`;
export const INPUT_REQUEST_SPECIAL_REQUEST = `${INPUT} ${CREATE_EDIT_REQUEST} Special request`;
export const BUTTON_MINIMUM_STAR_RATING = `${BUTTON} ${CREATE_EDIT_REQUEST}  Minimum star rating`;
export const BUTTON_ALLOWED_ROOMS = `${BUTTON} ${CREATE_EDIT_REQUEST}  Allowed rooms`;
export const BUTTON_REQUEST_INCLUDE_BREAKFAST = `${BUTTON} ${CREATE_EDIT_REQUEST} Include breakfast`;
export const BUTTON_REQUEST_INCLUDE_PARKING = `${BUTTON} ${CREATE_EDIT_REQUEST} Include parking`;
export const INPUT_SEARCH_ACCOUNT_NAME = `${INPUT} ${CREATE_EDIT_REQUEST} Search account name`;
export const INPUT_SEARCH_GUEST_NAME = `${INPUT} ${CREATE_EDIT_REQUEST} Search guest name`;
export const BUTTON_SEND_REQUEST = `${BUTTON} ${CREATE_EDIT_REQUEST} Send request`;

export const REQUESTS_LIST = `Requests list:`;
export const SCREEN_REQUESTS_LIST = `${SCREEN} Requests list`;
export const BUTTON_REQUESTS_FILTER_PENDING = `${BUTTON} ${REQUESTS_LIST} Filter by Pending`;
export const BUTTON_REQUESTS_FILTER_UPCOMING = `${BUTTON} ${REQUESTS_LIST} Filter by Upcoming`;
export const BUTTON_REQUESTS_FILTER_IN_PROGRESS = `${BUTTON} ${REQUESTS_LIST} Filter by In progress`;
export const BUTTON_REQUESTS_FILTER_ARCHIVE = `${BUTTON} ${REQUESTS_LIST} Filter by Archive`;
export const INPUT_REQUESTS_SEARCH_BY_REQUEST = `${INPUT} ${REQUESTS_LIST} Search by request`;
export const BUTTON_EDIT_REQUEST = `${BUTTON} ${REQUESTS_LIST} Edit request`;
export const BUTTON_CANCEL_REQUEST = `${BUTTON} ${REQUESTS_LIST} Cancel request`;

export const ACCOUNTS_PAGE = `Accounts page:`;
export const SCREEN_ACCOUNTS_PAGE = `${SCREEN} ${ACCOUNTS_PAGE} Accounts page`;
export const BUTTON_FILTER_ACCOUNTS_HOTELS = `${BUTTON} ${ACCOUNTS_PAGE} Filter accounts by Hotels`;
export const BUTTON_CREATE_HOTEL = `${BUTTON} ${ACCOUNTS_PAGE} Create hotel`;
export const BUTTON_EDIT_HOTEL = `${BUTTON} ${ACCOUNTS_PAGE} Edit hotel`;

export const FILTER_ACCOUNTS_CUSTOMERS = `Filter accounts by Customers:`;
export const BUTTON_FILTER_ACCOUNTS_CUSTOMERS = `${BUTTON} ${FILTER_ACCOUNTS_CUSTOMERS}`;
export const BUTTON_CREATE_CUSTOMER = `${BUTTON} ${FILTER_ACCOUNTS_CUSTOMERS} Create customer`;
export const BUTTON_EDIT_CUSTOMER = `${BUTTON} ${FILTER_ACCOUNTS_CUSTOMERS} Edit customer`;

export const CREATE_EDIT_HOTEL = `Create or edit hotel:`;
export const SCREEN_CREATE_EDIT_HOTEL = `${SCREEN} Create or edit hotel`;
export const INPUT_CREATE_HOTEL_NAME = `${INPUT} ${CREATE_EDIT_HOTEL} Hotel name`;
export const INPUT_HOTEL_ADDRESS = `${INPUT} ${CREATE_EDIT_HOTEL} Hotel address`;
export const INPUT_HOTEL_PHONE = `${INPUT} ${CREATE_EDIT_HOTEL} Hotel phone number`;
export const INPUT_HOTEL_EMAIL = `${INPUT} ${CREATE_EDIT_HOTEL} Hotel email`;
export const BUTTON_SAVE_HOTEL = `${BUTTON} ${CREATE_EDIT_HOTEL} Save`;

export const CREATE_EDIT_CUSTOMER = `Create or update customer:`;
export const SCREEN_CREATE_EDIT_CUSTOMER = `${SCREEN} Create or update customer`;
export const INPUT_ACCOUNT_NAME = `${INPUT} ${CREATE_EDIT_CUSTOMER} Account name`;
export const INPUT_ACCOUNT_ADDRESS = `${INPUT} ${CREATE_EDIT_CUSTOMER} Account address`;
export const BUTTON_SAVE_CUSTOMER = `${BUTTON} ${CREATE_EDIT_CUSTOMER} Save`;

export const USERS_LIST = `Users list:`;
export const SCREEN_USERS_LIST = `${SCREEN} Users list`;

export const BUTTON_USERS_FILTER_ACTIVE = `${BUTTON} ${USERS_LIST} Filter by Active`;
export const BUTTON_USERS_FILTER_INVITED = `${BUTTON} ${USERS_LIST} Filter by Invited`;
export const BUTTON_USERS_FILTER_DEACTIVATED = `${BUTTON} ${USERS_LIST} Filter by Deactivated`;
export const BUTTON_EDIT_USER = `${BUTTON} ${USERS_LIST} Edit user`;
export const BUTTON_INVITE_USER = `${BUTTON} ${USERS_LIST} Invite user`;

export const EDIT_USER = `Edit user:`;
export const SCREEN_EDIT_USER = `${SCREEN} Edit user`;
export const INPUT_FIRST_NAME = `${INPUT} ${EDIT_USER} First name`;
export const INPUT_LAST_NAME = `${INPUT} ${EDIT_USER} Last name`;
export const INPUT_SELECT_ROLE = `${INPUT} ${EDIT_USER} Select role`;
export const BUTTON_DEACTIVATE_OR_ACTIVATE_USER = `${BUTTON} ${EDIT_USER} Deactivate or activate`;
export const BUTTON_SAVE_USER = `${BUTTON} ${EDIT_USER} Save`;

export const INVITE_USER = `Invite user`;
export const MODAL_INVITE_USER = `${MODAL} Invite user`;
export const INPUT_INVITE_USER_EMAIL = `${INPUT} ${INVITE_USER} User email`;
export const INPUT_INVITE_USER_ROLE = `${INPUT} ${INVITE_USER} User role`;
export const BUTTON_SEND_INVITE = `${BUTTON} ${INVITE_USER} Send invite`;
