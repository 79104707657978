import downloadFile from "../../../helpers/downloadFile";
import apiClient from "../../../services/api";
import { getDownloadInvoiceFileRoute } from "../../../services/routes";

const downloadInvoiceFile = async ({id, fileId}:{id: number; fileId: number}): Promise<unknown> => {

    const result = await apiClient
        .get(getDownloadInvoiceFileRoute(id, fileId), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, `GET-E Inovice ${id}.csv`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadInvoiceFile;
