import { differenceInSeconds } from 'date-fns';

import { Validated } from '../Validator';

const isAfterTime = <TError>(
    value: Date | null,
    minimum: Date | null,
    error: TError
): Validated<Date, TError> =>
    (value === null
  || (minimum !== null && differenceInSeconds(value, minimum) < 0)
        ? {
            isValid: false,
            error,
        }
        : {
            isValid: true,
            value,
        });

export default isAfterTime;
