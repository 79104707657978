import { differenceInCalendarDays, differenceInHours, format, parseISO } from 'date-fns';
import moment from 'moment';

import { DATE_FORMATS } from './dateFormats';
import { TIME_FORMATS } from './timeFormats';

export const getCheckDate = (date: string) => {
    return format(parseISO(date), 'dd MMM');
};

export const getDifferenceInDays = (date1: string, date2: string) =>
    differenceInCalendarDays(parseISO(date1), parseISO(date2));

export const getDifferenceInHours = (date1: string, date2: string) =>
    differenceInHours(parseISO(date1), parseISO(date2));

export const getFormattedUtcDateTimeString = (
    dateTimeString: string,
    dateFormat: DATE_FORMATS,
    timeFormat: TIME_FORMATS,
    showTime?: boolean,
) => {
    const timeFormatResolved = showTime ? ` (${timeFormat})` : '';
    const dateTimeFormat = `${dateFormat}${timeFormatResolved}`;

    return !dateTimeString || !moment.utc(dateTimeString).isValid()
        ? ''
        : moment.utc(dateTimeString).format(dateTimeFormat);
};

export const getFormattedDateTimeString = (
    dateTimeString: string,
    dateFormat: DATE_FORMATS,
    timeFormat: TIME_FORMATS,
    showTime?: boolean,
    withoutWrapperParentheses?: boolean,
) => {
    const timeFormatResolved = showTime ? (withoutWrapperParentheses ? ` ${timeFormat}` : ` (${timeFormat})`) : '';
    const dateTimeFormat = `${dateFormat}${timeFormatResolved}`;

    return !dateTimeString || !moment(dateTimeString).isValid()
        ? ''
        : moment(dateTimeString).format(dateTimeFormat);
};

export const getDaysMonthUtcRangeString = (
    startDateString?: string,
    endDateString?: string,
) => {
    if (
        !startDateString ||
        !endDateString ||
        !moment.utc(startDateString).isValid() ||
        !moment.utc(endDateString).isValid()
    ) {
        return '';
    }

    const startDate = moment.utc(startDateString);
    const endDate = moment.utc(endDateString);
    const startDateDayString = startDate.format(DATE_FORMATS.DD);
    const endDateDayString = endDate.format(DATE_FORMATS.DD);
    const startDateMonthString = startDate.format(DATE_FORMATS.MMM);
    const endDateMonthString = endDate.format(DATE_FORMATS.MMM);

    if (startDateMonthString === endDateMonthString) {
        return `${startDateDayString} - ${endDateDayString} ${startDateMonthString}`;
    } else {
        return `${startDateDayString} ${
            startDateMonthString === endDateMonthString ? '' : startDateMonthString
        } - ${endDateDayString} ${endDateMonthString}`;
    }
};

export const getDaysMonthRangeString = (
    startDateString?: string,
    endDateString?: string,
) => {
    if (
        !startDateString ||
        !endDateString ||
        !moment(startDateString).isValid() ||
        !moment(endDateString).isValid()
    ) {
        return '';
    }

    const startDate = moment(startDateString);
    const endDate = moment(endDateString);
    const startDateDayString = startDate.format(DATE_FORMATS.DD);
    const endDateDayString = endDate.format(DATE_FORMATS.DD);
    const startDateMonthString = startDate.format(DATE_FORMATS.MMM);
    const endDateMonthString = endDate.format(DATE_FORMATS.MMM);

    if (startDateMonthString === endDateMonthString) {
        return `${startDateDayString} - ${endDateDayString} ${startDateMonthString}`;
    } else {
        return `${startDateDayString} ${
            startDateMonthString === endDateMonthString ? '' : startDateMonthString
        } - ${endDateDayString} ${endDateMonthString}`;
    }
};
