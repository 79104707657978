import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    Card,
    CardContent,
    ClickAwayListener,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { BookingStatus, BookingGuest, BookingCustomField } from '../../api/types';
import { COLORS } from '../../../../constans/colors';
import { getFormattedDateTimeString } from '../../../../helpers/dateUtils';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { TIME_FORMATS } from '../../../../helpers/timeFormats';
import { useEffect, useState } from 'react';
import StatusButton from './hotelUserBookingCard/StatusButton';
import StatusList from './hotelUserBookingCard/StatusList';
import { PrimaryButton } from '@get-e/react-components';
import EditOffRoundedIcon from '@mui/icons-material/EditOffRounded';
import { useMutation } from 'react-query';
import { confirm } from '../../../../services/HotelBooking';
import {
    Severity,
    useNotificationContext,
} from '../../../../context/NotificationContext';
import { getBookingStatusKeyName } from '../../../../helpers/bookingStatusUtil';
import getDueInTime from '../../../../helpers/getDueInTime';

const useStyles = makeStyles({
    textContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    userIcon: {
        marginRight: '0.5rem',
    },
    heading: {
        display: 'flex',
        flexDirection: 'row',
    },
    headingMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '1rem',
    },
    primaryHeading: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
    },
    strikethroughText: {
        fontSize: '0.8rem',
        color: COLORS.SLATE_GREY,
        textDecoration: 'line-through',
    },
    secondaryText: {
        display: 'inline',
        fontSize: '0.8rem',
    },
    durationText: {
        color: COLORS.SLATE_GREY,
        fontSize: '0.7rem',
    },
    bookingStatus: {
        lineHeight: '0.5rem',
        width: '100%',
    },
    saveButton: {
        width: '80%',
        height: '2rem',
        marginTop: '-0.3rem',
    },
});

interface BookingCardProps {
    bookingId: number;
    status: BookingStatus;
    bookingNumber: string;
    hotelComments: string;
    checkInDate: string;
    checkOutDate: string;
    guests: BookingGuest[];
    customerName: string;
    units: number;
    pendingUpdates: any;
    customFields: BookingCustomField[];
    timeToAction?: string;
    updateBookingsChangeList: (
        id: number,
        status: BookingStatus,
        confirmationNumber: string,
        hotelComments: string,
    ) => void;
    removeFromUpdateList: (id: number) => void;
}

const HotelUserBookingCard = ({
    bookingId,
    status,
    bookingNumber,
    hotelComments,
    checkInDate,
    checkOutDate,
    guests,
    customerName,
    units,
    pendingUpdates,
    customFields,
    timeToAction,
    updateBookingsChangeList,
    removeFromUpdateList,
}: BookingCardProps) => {
    const classes = useStyles();
    const { showNotification } = useNotificationContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const {useTimeToActionOnBookings} = useFlags();
    const [bookingStatus, setBookingStatus] = useState<BookingStatus>(status);
    const [confirmationNumber, setConfirmationNumber] = useState<string>(bookingNumber);
    const [bookingHotelComments, setHotelComments] = useState<string>(hotelComments);
    const [isDerty, setIsDerty] = useState(false);
    const shouldStatusChange = (status: BookingStatus): boolean => {
        return Array.of(
            BookingStatus.BOOKING_PENDING,
            BookingStatus.BOOKING_UPDATE_PENDING,
            BookingStatus.PROCESS_CANCELLATION,
        ).includes(status);
    };

    const shouldBookingNumberChange = (
        status: BookingStatus,
        confirmationNumber: string,
    ): boolean => {
        return (
            status === BookingStatus.BOOKING_PENDING ||
            status === BookingStatus.BOOKING_UPDATE_PENDING ||
            (status === BookingStatus.BOOKING_CONFIRMED &&
                (confirmationNumber === null || confirmationNumber.length < 1))
        );
    };

    const [allowStatusChange, setAllowStatusChange] = useState(
        shouldStatusChange(bookingStatus),
    );
    const [allowChangeConfirmationNumber, setAllowChangeConfirmationNumber] = useState(
        shouldBookingNumberChange(bookingStatus, confirmationNumber),
    );

    const toggleStatusList = () => {
        setIsStatusListOpen(prev => !prev);
    };

    const [isStatusListOpen, setIsStatusListOpen] = useState(false);

    const handleStatusItemClick = (newStatus: keyof typeof BookingStatus) => {
        setBookingStatus(BookingStatus[newStatus]);
        setIsStatusListOpen(false);

        if (
            BookingStatus[newStatus] === BookingStatus.NOT_AVAILABLE ||
            BookingStatus[newStatus] === BookingStatus.BOOKING_CANCELLED ||
            BookingStatus[newStatus] === BookingStatus.PROCESS_CANCELLATION
        ) {
            setAllowChangeConfirmationNumber(false);
        } else {
            setAllowChangeConfirmationNumber(
                bookingNumber === null ||
                    bookingNumber.length < 1 ||
                    status === BookingStatus.BOOKING_UPDATE_PENDING,
            );
        }
    };

    const handleClickAway = () => {
        setIsStatusListOpen(false);
    };

    useEffect(() => {
        if (
            bookingStatus !== status ||
            // It's to prevent user changing confirmation number while status is stil PENDING
            (bookingNumber !== confirmationNumber &&
                bookingStatus !== BookingStatus.BOOKING_UPDATE_PENDING) ||
            hotelComments !== bookingHotelComments
        ) {
            setIsDerty(true);
            updateBookingsChangeList(
                bookingId,
                bookingStatus,
                confirmationNumber,
                bookingHotelComments,
            );
        } else {
            setIsDerty(false);
            removeFromUpdateList(bookingId);
        }
    }, [bookingStatus, confirmationNumber, bookingHotelComments]);

    const { mutate: confirmBooking, isLoading: isLoadingEdit } = useMutation(confirm, {
        onSuccess: response => {
            setBookingStatus(response.data[0].status);
            setConfirmationNumber(response.data[0].bookingNumber);
            setHotelComments(response.data[0].hotelComments);
            setAllowStatusChange(shouldStatusChange(response.data[0].status));
            setAllowChangeConfirmationNumber(
                shouldBookingNumberChange(
                    response.data[0].status,
                    response.data[0].bookingNumber,
                ),
            );
            removeFromUpdateList(bookingId);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(
                error.response?.data?.message ?? 'Something went wrong',
                Severity.Error,
            );
        },
    });

    const saveChanges = () => {
        confirmBooking({
            payload: {
                list: [
                    {
                        id: bookingId,
                        status: getBookingStatusKeyName(bookingStatus),
                        bookingNumber: confirmationNumber,
                        hotelComments: bookingHotelComments,
                    },
                ],
            },
        });
        setIsDerty(false);
    };

    const getAvailableStatuses = (): BookingStatus[] => {
        if (status === BookingStatus.BOOKING_PENDING) {
            return [
                BookingStatus.BOOKING_PENDING,
                BookingStatus.BOOKING_CONFIRMED,
                BookingStatus.NOT_AVAILABLE,
            ];
        }

        if (status === BookingStatus.PROCESS_CANCELLATION) {
            return [BookingStatus.PROCESS_CANCELLATION, BookingStatus.BOOKING_CANCELLED];
        }

        if (status === BookingStatus.BOOKING_UPDATE_PENDING) {
            return [
                BookingStatus.BOOKING_UPDATE_PENDING,
                BookingStatus.BOOKING_CONFIRMED,
            ];
        }

        return [];
    };

    const hasPendingUpdate =
        status === BookingStatus.BOOKING_UPDATE_PENDING &&
        Object.keys(pendingUpdates).length;

    return (
        <Grid paddingBottom={1}>
            <Card
                variant="outlined"
                sx={{
                    ':hover': {
                        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
                        cursor: 'pointer',
                    },
                    overflow: 'visible',
                }}
            >
                <CardContent sx={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem !important' }}>
                    <div className={isMobile ? classes.headingMobile : classes.heading}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid
                                item
                                xs={isMobile ? 12 : 1}
                                style={{
                                    paddingTop: '0.8rem',
                                    paddingLeft: isMobile ? '0.6rem' : '1.5rem',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.primaryHeading}
                                >
                                    {' '}
                                    B{bookingId}{' '}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 0.9}
                                style={{
                                    paddingTop: '0.8rem',
                                    paddingLeft: isMobile ? '0.6rem' : '0',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.primaryHeading}
                                >
                                    {customFields.find(
                                        (field: BookingCustomField) =>
                                            field.key == 'is-training-booking' &&
                                            field.value == '1',
                                    )
                                        ? 'Training'
                                        : 'Scheduled'}
                                </Typography>
                            </Grid>
                            {/* Check-in & Check-out */}
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.6}
                                style={{
                                    paddingTop: '0.8rem',
                                    paddingLeft: isMobile ? '0.6rem' : '0',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.primaryHeading}
                                >
                                    {getFormattedDateTimeString(
                                        checkInDate,
                                        DATE_FORMATS['DD MMM'],
                                        TIME_FORMATS['HH:mm'],
                                        true,
                                        true,
                                    )}{' '}
                                    -{' '}
                                    {getFormattedDateTimeString(
                                        checkOutDate,
                                        DATE_FORMATS['DD MMM'],
                                        TIME_FORMATS['HH:mm'],
                                        true,
                                        true,
                                    )}
                                    <span className={classes.durationText}>
                                        &nbsp;({units} nights)
                                    </span>
                                </Typography>
                            </Grid>
                            {/* Guest */}
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                style={{
                                    paddingTop: '0.8rem',
                                    paddingLeft: isMobile ? '0.6rem' : '0',
                                }}
                            >
                                <div className={classes.textContainer}>
                                    <PersonIcon
                                        sx={{ fontSize: '1.2rem' }}
                                        className={classes.userIcon}
                                    />
                                    <Typography
                                        variant="body1"
                                        className={classes.secondaryText}
                                    >
                                        {guests &&
                                            guests[0].first_name +
                                                ' ' +
                                                guests[0].last_name}
                                    </Typography>
                                </div>
                            </Grid>
                            {/* Status */}
                            <Grid
                                item
                                xs={isMobile ? 12 : 2}
                                style={{
                                    textAlign: 'center',
                                    padding: isMobile
                                        ? '0.6rem 0 0 0.6rem'
                                        : '0.6rem 0 0 0',
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Grid
                                        item
                                        textAlign={isMobile ? 'left' : 'center'}
                                        sx={{ position: 'relative' }}
                                    >
                                        <StatusButton
                                            className={classes.bookingStatus}
                                            variant={bookingStatus}
                                            onClick={
                                                allowStatusChange
                                                    ? toggleStatusList
                                                    : undefined
                                            }
                                            showDropdownButton={allowStatusChange}
                                            disablePulseEffect={
                                                allowStatusChange ? false : true
                                            }
                                        >
                                            {bookingStatus ?? ''}
                                        </StatusButton>

                                        <StatusList
                                            includedStatues={getAvailableStatuses()}
                                            width={110}
                                            isListVisible={isStatusListOpen}
                                            onItemClick={handleStatusItemClick}
                                        />
                                    </Grid>
                                </ClickAwayListener>
                            </Grid>
                            {/* Confirmation Number */}
                            <Grid
                                item
                                xs={isMobile ? 12 : 2}
                                style={{ paddingLeft: '0.6rem' }}
                            >
                                <div className={classes.textContainer}>
                                    <TextField
                                        label="Confirmation Number"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: '100%' }}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '2rem',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '0.9rem',
                                            },
                                            '& .css-dynq5g-MuiFormLabel-root-MuiInputLabel-root':
                                                {
                                                    fontSize: '0.8rem',
                                                    marginTop: '-0.2rem',
                                                },
                                        }}
                                        disabled={!allowChangeConfirmationNumber}
                                        value={confirmationNumber}
                                        onChange={event => {
                                            setConfirmationNumber(event.target.value);

                                            if (
                                                (event.target.value.length > 0 &&
                                                    status !==
                                                        BookingStatus.BOOKING_UPDATE_PENDING) ||
                                                (event.target.value !== bookingNumber &&
                                                    status ===
                                                        BookingStatus.BOOKING_UPDATE_PENDING)
                                            ) {
                                                setBookingStatus(
                                                    BookingStatus.BOOKING_CONFIRMED,
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                            {/* Action Buttons | Customer Name */}
                            <Grid
                                item
                                xs={isMobile ? 12 : 1.3}
                                style={{ paddingTop: '0.8rem', paddingLeft: '0.6rem' }}
                            >
                                {isDerty ? (
                                    <div className={classes.textContainer}>
                                        <PrimaryButton
                                            onClick={saveChanges}
                                            className={classes.saveButton}
                                        >
                                            Save
                                        </PrimaryButton>
                                        <Button
                                            onClick={() => {
                                                setBookingStatus(status);
                                                setConfirmationNumber(
                                                    bookingNumber ?? '',
                                                );
                                                setHotelComments(hotelComments ?? '');
                                                setAllowChangeConfirmationNumber(
                                                    shouldBookingNumberChange(
                                                        status,
                                                        bookingNumber,
                                                    ),
                                                );
                                                setIsDerty(false);
                                            }}
                                            className={classes.saveButton}
                                            style={{
                                                border: '1px solid',
                                                minWidth: '5px',
                                                width: '5px',
                                                marginLeft: '0.2rem',
                                            }}
                                        >
                                            <EditOffRoundedIcon fontSize="small"></EditOffRoundedIcon>
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.textContainer}>
                                        <BusinessIcon
                                            sx={{ fontSize: '1.1rem' }}
                                            className={classes.userIcon}
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{ fontSize: '0.8rem' }}
                                        >
                                            {customerName}
                                        </Typography>
                                    </div>
                                )}
                            </Grid>

                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 1}
                                    style={{
                                        color: COLORS.SLATE_GREY,
                                        paddingLeft: isMobile ? '0.6rem' : '1.5rem',
                                        fontSize: isMobile ? '1.1rem' : '0.8rem',
                                        marginTop: isMobile ? '1.5rem' : '0',
                                    }}
                                    className={classes.secondaryText}
                                >
                                    Changes
                                    {isMobile && (
                                        <div
                                            style={{
                                                borderBottom: '1px dashed gray',
                                            }}
                                        ></div>
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}

                            {/* Old Is Training status */}
                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 0.9}
                                    style={{ paddingLeft: isMobile ? '0.6rem' : '0' }}
                                >
                                    {/* Check if the old is-training-booking custom field is different from current is-training-booking custom field */}
                                    {pendingUpdates.customFields !== undefined &&
                                        pendingUpdates.customFields.oldValue.find(
                                            (field: any) =>
                                                field.customFieldKey ==
                                                    'is-training-booking' &&
                                                field.value !=
                                                    (customFields.find(
                                                        (field: BookingCustomField) =>
                                                            field.key ==
                                                            'is-training-booking',
                                                    )?.value ?? '0'),
                                        ) && (
                                            <Typography
                                                variant="body1"
                                                className={classes.strikethroughText}
                                            >
                                                {pendingUpdates.customFields.oldValue.find(
                                                    (field: any) =>
                                                        field.customFieldKey ==
                                                            'is-training-booking' &&
                                                        field.value == '1',
                                                )
                                                    ? 'Training'
                                                    : 'Scheduled'}
                                            </Typography>
                                        )}
                                </Grid>
                            ) : (
                                ''
                            )}

                            {/* Old CheckIn/CheckOut */}
                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 2.6}
                                    style={{ paddingLeft: isMobile ? '0.6rem' : '0' }}
                                >
                                    {(pendingUpdates.checkInDateLocal !== undefined ||
                                        pendingUpdates.checkOutDateLocal !== undefined ||
                                        pendingUpdates.units !== undefined) && (
                                        <Typography
                                            variant="body1"
                                            className={classes.strikethroughText}
                                        >
                                            {getFormattedDateTimeString(
                                                pendingUpdates.checkInDateLocal
                                                    ?.oldValue ?? checkInDate,
                                                DATE_FORMATS['DD MMM'],
                                                TIME_FORMATS['HH:mm'],
                                                true,
                                                true,
                                            )}{' '}
                                            -{' '}
                                            {getFormattedDateTimeString(
                                                pendingUpdates.checkOutDateLocal
                                                    ?.oldValue ?? checkOutDate,
                                                DATE_FORMATS['DD MMM'],
                                                TIME_FORMATS['HH:mm'],
                                                true,
                                                true,
                                            )}
                                            <span className={classes.durationText}>
                                                &nbsp;(
                                                {pendingUpdates.units?.oldValue ??
                                                    units}{' '}
                                                nights)
                                            </span>
                                        </Typography>
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}

                            {/* Old Guest */}
                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 2.2}
                                    style={{ paddingLeft: isMobile ? '0.6rem' : '0' }}
                                >
                                    {pendingUpdates.guest !== undefined && (
                                        <div className={classes.textContainer}>
                                            <PersonIcon
                                                sx={{ fontSize: '1.2rem' }}
                                                className={classes.userIcon}
                                            />
                                            <Typography
                                                variant="body1"
                                                className={classes.strikethroughText}
                                            >
                                                {pendingUpdates.guest.oldValue.firstName +
                                                    ' ' +
                                                    pendingUpdates.guest.oldValue
                                                        .lastName}
                                            </Typography>
                                        </div>
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}

                            {/* Old Status */}
                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 2}
                                    style={{
                                        textAlign: isMobile ? 'left' : 'center',
                                        padding: isMobile
                                            ? '0.6rem 0 0 0.6rem'
                                            : '0.6rem 0 0 0',
                                    }}
                                >
                                    {pendingUpdates.status !== undefined && (
                                        <Typography
                                            variant="body1"
                                            className={classes.strikethroughText}
                                        >
                                            {
                                                BookingStatus[
                                                    pendingUpdates.status
                                                        .oldValue as keyof typeof BookingStatus
                                                ]
                                            }
                                        </Typography>
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}

                            {/* Old Confirmation Number */}
                            {hasPendingUpdate ? (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 2}
                                    style={{ textAlign: isMobile ? 'left' : 'center' }}
                                >
                                    {pendingUpdates.bookingNumber !== undefined && (
                                        <Typography
                                            variant="body1"
                                            className={classes.strikethroughText}
                                        >
                                            {pendingUpdates.bookingNumber.oldValue}
                                        </Typography>
                                    )}
                                    {isMobile && (
                                        <div
                                            style={{
                                                borderBottom: '1px dashed gray',
                                            }}
                                        ></div>
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}
                           {useTimeToActionOnBookings && timeToAction && <Grid item xs={12} paddingTop={`0 !important`}>
                                <Typography
                                    variant="h6"
                                    className={classes.primaryHeading}
                                >
                                    {getDueInTime(timeToAction)}
                                </Typography>
                            </Grid>}

                            {/* Hotel Comments */}
                            <Grid item xs={12}>
                                <div className={classes.textContainer}>
                                    <TextField
                                        label="Hotel Comments"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: '100%' }}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '2rem',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '0.9rem',
                                            },
                                            '& .css-dynq5g-MuiFormLabel-root-MuiInputLabel-root':
                                                {
                                                    fontSize: '0.8rem',
                                                    marginTop: '-0.2rem',
                                                },
                                        }}
                                        value={bookingHotelComments}
                                        onChange={event => {
                                            setHotelComments(event.target.value);
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default HotelUserBookingCard;
