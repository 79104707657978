import { AddressRequest } from '../../pages/accounts/api/types';

function isAnAddress(obj: any): boolean {
    const objectKeys = Object.keys(obj);
    const requiredKeys = [
        'full_address',
        'id',
        'country',
        'address_line',
        'city',
        'state_province',
        'postal_code',
    ];

    return requiredKeys.every(i => objectKeys.includes(i));
}

export const mapAddressToAddressRequest = (newAddress: any): AddressRequest => {
    if (isAnAddress(newAddress)) {
        return {
            city: newAddress.city ?? '',
            state_province: newAddress.state_province,
            postal_code: newAddress.postal_code,
            country_code: newAddress.country.alpha2,
            address_line: newAddress.address_line,
            lat: newAddress.lat,
            long: newAddress.long,
        };
    }
    return newAddress;
};
