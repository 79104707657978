import { Validated } from '../Validator';

const isFilledArray = <TValue, TError>(
    value: TValue[] | null,
    error: TError,
): Validated<TValue[], TError> => (
    value === null || value.length === 0
        ? {
            isValid: false,
            error,
        }
        : {
            isValid: true,
            value,
        }
);

export default isFilledArray;
