import { useQuery } from "react-query";

import useDebounce from "../../../../helpers/useDebounce";
import { listRelationNames } from "../../../../services/statement";
import { ALL_HOTELS_CUSTOMERS } from "../../../../constans/queryKeys";

export const useRelationNames = ( search: string, hotelId?: number, limit?: number) => {
    const debouncedSearchQuery = useDebounce(search, 400);

    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [ALL_HOTELS_CUSTOMERS + debouncedSearchQuery],
        () => listRelationNames(hotelId, debouncedSearchQuery, limit),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: false,
            enabled: !!hotelId
        }
    );

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};