import { FunctionComponent } from 'react';
import { MessageDialog } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constans/colors';

const useStyles = makeStyles(() => ({
    confirmButton: {
        background: COLORS.RED,
    },
}));
interface SaveBookingModalProps {
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    isCancelling?: boolean;
    isSendingEmails?: boolean;
    isEditing?: boolean;
    isAdding?: boolean;
}

const SaveBookingModal: FunctionComponent<SaveBookingModalProps> = ({
    onClose,
    onConfirm,
    title,
    isCancelling,
    isSendingEmails,
    isEditing,
    isAdding,
}) => {
    const classes = useStyles();

    let message = '';
    if (isCancelling && isSendingEmails) {
        message = 'You are about to cancel this booking and send one or more emails.';
    } else if (isCancelling) {
        message = 'You are about to cancel this booking.';
    } else if (isSendingEmails) {
        message = 'You are about to send one or more emails.';
    } else {
        if (isEditing) {
            message = 'You are about to edit this booking.';
        } else if (isAdding) {
            message = 'You are about to add this booking.';
        }
    }

    return (
        <MessageDialog
            onClose={onClose}
            onConfirm={onConfirm}
            title={title}
            isConfirmButtonDisplayed
            closeButtonLabel="Back"
            confirmButtonLabel="Continue"
            closeButtonType="secondary"
            confirmButtonClassName={classes.confirmButton}
        >
            <Typography sx={{ padding: '1rem 0' }}>
                {message} <br />
                <br />
                <strong>Are you sure you want to proceed?</strong>
            </Typography>
        </MessageDialog>
    );
};

export default SaveBookingModal;
