import { useEffect, useState } from 'react';
import { Box, Fade, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../../../../constans/colors';
import { BookingStatus } from '../../../api/types';
import { getStatusColor } from '../../../../../helpers/bookingStatusUtil';

const useStyles = makeStyles({
    statusList: {
        width: '100%',
        position: 'absolute',
        top: '2em',
        right: '0',
        zIndex: '100',
        backgroundColor: COLORS.WHITE,
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        padding: '.5rem 0'
    },
    circle: {
        borderRadius: '50%',
        width: '0.75rem',
        height: '0.75rem',
    },
});

interface StatusListProps {
    includedStatues: BookingStatus[];
    width?: number;
    isListVisible: boolean;
    onItemClick: (value: keyof typeof BookingStatus) => void;
}

const StatusList = ({ includedStatues, width,isListVisible, onItemClick }: StatusListProps) => {
    const classes = useStyles();
    const [isStatusListOpen, setIsStatusListOpen] = useState(false);

    useEffect(() => {
        setIsStatusListOpen(isListVisible);
    }, [isListVisible]);

    const handleItemClick = (status: keyof typeof BookingStatus) => {
        onItemClick(status);
        setIsStatusListOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Fade in={isStatusListOpen}>
                    <List disablePadding className={classes.statusList} sx={{
                        width: `${width}%!important`
                    }}>
                        {Object.keys(BookingStatus)
                        .filter( value => includedStatues.includes(BookingStatus[value as keyof typeof BookingStatus]))
                        .map(value => {
                            return (
                                <ListItem key={value} sx={{ padding: '0' }}>
                                    <ListItemButton
                                        onClick={() =>
                                            handleItemClick(
                                                value as keyof typeof BookingStatus,
                                            )
                                        }
                                        sx={{ padding: '.875rem 1.375rem'}}
                                    >
                                        <ListItemText
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: BookingStatus[
                                                    value as keyof typeof BookingStatus
                                                ].length > 15 ? "0.9rem" : "1rem",
                                            },
                                        }}
                                            primary={
                                                BookingStatus[
                                                    value as keyof typeof BookingStatus
                                                ]
                                            }
                                        />
                                        <Box
                                            className={classes.circle}
                                            sx={{
                                                background: getStatusColor(
                                                    value as keyof typeof BookingStatus,
                                                ),
                                            }}
                                        ></Box>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Fade>
            </Box>
        </>
    );
};

export default StatusList;
