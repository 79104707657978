import makeStyles from '@mui/styles/makeStyles';
import uuid from 'react-uuid';
import { Card, CardContent, Grid } from '@mui/material';

import { Guest, Booking } from '../../api/types';
import StatusButton from '../StatusButton';
import RequestHeading from './RequestHeading';
import RequestGuest from './RequestGuest';
import BookingHotel from './BookingHotel';
import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles({
    card: {
        border: `1px solid ${COLORS.DARK_GRAY}`,
        margin: '.75rem 0 .75rem 0',
        padding: '2rem',
    },
    searchingButton: {
        marginTop: '1rem',
    },
});

interface RequestCardProps {
    requestAddress: string;
    requestId: number;
    status: string;
    checkInDate: string;
    checkOutDate: string;
    bookings: Booking[];
    guests: Guest[];
    onClick: (id: number) => void;
    customerName: string;
}

const RequestCard = ({
    requestAddress,
    requestId,
    status,
    checkInDate,
    checkOutDate,
    guests,
    bookings,
    onClick,
    customerName
}: RequestCardProps) => {
    const classes = useStyles();

    return (
        <Grid item paddingBottom={4}>
            <Card
                variant="outlined"
                sx={{
                    ':hover': {
                        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
                        cursor: 'pointer',
                    },
                }}
            >
                <CardContent onClick={() => onClick(requestId)}>
                    <RequestHeading
                        status={status}
                        checkInDate={checkInDate}
                        checkOutDate={checkOutDate}
                        requestAddress={requestAddress}
                        requestId={requestId}
                    />
                    {bookings?.length === 0 ? (
                        <StatusButton className={classes.searchingButton}>
                            Searching rooms
                        </StatusButton>
                    ) : (
                        bookings?.map(booking => {
                            return (
                                <BookingHotel
                                    checkInDate={booking.checkInDateLocal}
                                    checkOutDate={booking.checkOutDateLocal}
                                    hotelName={booking?.hotelName}
                                    bookingNumber={booking?.bookingNumber}
                                    status={booking?.status}
                                    key={uuid()}
                                />
                            );
                        })
                    )}
                    {guests?.map(guest => (
                        <RequestGuest
                            key={uuid()}
                            firstName={guest?.first_name}
                            lastName={guest?.last_name}
                            employeeId={''}
                            customerName={customerName}
                        />
                    ))}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default RequestCard;
