function downloadFile(url: string, fileName: string): void {
    const link = document.createElement('a');

    link.href = url;
    link.download = `${fileName}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export default downloadFile;
