import Retry from "../../components/Retry";
import CenterContainer from "../../components/CenterContainer";

const ErrorBoundaryPage = () => {

    return (
        <CenterContainer>
            <Retry onRetry={() => console.log('Retrying')} />
        </CenterContainer>
    );
}

export default ErrorBoundaryPage;