import { useQuery } from 'react-query';

import { ALL_ROOM_TYPES } from '../../../constans/queryKeys';
import { listRoomTypes } from '../../../services/traveller';

export function useRoomTypes() {
    const {
        data: roomTypes,
    } = useQuery([ALL_ROOM_TYPES], listRoomTypes, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { roomTypes };
}
