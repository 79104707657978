import { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Heading } from '@get-e/react-components';

const useStyles = makeStyles(() => ({
    header: { marginBottom: '1rem' },
}));

interface PageTitleProps {
    level?: 1 | 2 | 3 | 4;
    title?: string;
    className?: string;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({
    level = 2,
    title = '',
    className = ''
}) => {
    const classes = useStyles();

    return (
        <div  className={clsx(classes.header, className)}>
            <Heading level={level}>{title}</Heading>
        </div>
    );
};

export default PageTitle;
