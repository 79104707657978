import { FinanceTransaction } from '../pages/hotels/api/types';
import { Booking } from '../pages/reservations/api/types';

export const calculateBookingPrice = (transactions: FinanceTransaction[]|undefined): string|null => {
    if(transactions === undefined) {
        return null
    }

    let currency: string | undefined = transactions.find(transaction => transaction.type === 'REVENUE')?.currency || '';


    let totalAmount: number = transactions.reduce((total, transaction) => {
        return total + (transaction.type === "REVENUE" ? transaction.amount * transaction.units : 0);
    }, 0);

    return totalAmount + ' ' + currency;
};

export const calculateCommissionPrice = (transactions: FinanceTransaction[]|undefined): string| null => {
    if(transactions === undefined) {
        return null
    }

    let currency: string | undefined = transactions.find(transaction => transaction.type === 'COMMISSION')?.currency;

    if(currency === undefined) {
        return null
    }

    let totalAmount: number = transactions.reduce((total, transaction) => {
        return total + (transaction.type === "COMMISSION" ? transaction.amount * transaction.units : 0);
    }, 0);

    return totalAmount + ' ' + currency;
};

export const calculateFeePrice = (transactions: FinanceTransaction[]|undefined): string| null => {
    if(transactions === undefined) {
        return null
    }

    let currency: string | undefined = transactions.find(transaction => transaction.type === 'FEE')?.currency;

    if(currency === undefined) {
        return null
    }

    let totalAmount: number = transactions.reduce((total, transaction) => {
        return total + (transaction.type === "FEE" ? transaction.amount * transaction.units : 0);
    }, 0);

    return totalAmount + ' ' + currency;
};