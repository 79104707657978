import { useEffect, useMemo, useState } from 'react';
import {
    NumberedTabs,
    NumberedTab,
    TabPanel,
    Autocomplete,
} from '@get-e/react-components';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import { RequestTab } from '../index';
import {
    REQUESTS_ARCHIVE,
    REQUESTS_UPCOMING,
    REQUESTS_PENDING,
    REQUESTS_IN_PROGRESS,
} from '../../../constans/urlPaths';
import PageTitle from '../../../components/PageTitle';
import { useRequestSearch } from '../api/useRequestSearch';
import List from './requestsList/List';
import { useSearch } from '../api/useSearch';
import { SearchOption } from '../api/types';
import { setSearch } from '../../../store/searchSlice';
import { RootState } from '../../../store/store';
import { toUpperCaseFirstLetter } from '../../../helpers/toUpperCaseFirstLetter';
import { COLORS } from '../../../constans/colors';
import { logAmplitudeEvent } from "../../../amplitude/amplitude";
import { logAmplitudeRequestTab } from '../../../helpers/logAmplitudeTab';
import { INPUT_REQUESTS_SEARCH_BY_REQUEST, SCREEN_REQUESTS_LIST } from '../../../amplitude/amplKeys';

const useStyles = makeStyles(() => ({
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    searchBox: {
        marginBottom: '2rem',
        marginTop: '2rem',
    },
}));

const getActiveTab = (tab: number) => {
    if (tab === RequestTab.Pending) return 'pending';
    else if (tab === RequestTab.Archive) return 'archive';
    else if (tab === RequestTab.InProgress) return 'in_progress';
    else if (tab === RequestTab.Upcoming) return 'upcoming';
};

interface RequestListProps {
    tab: RequestTab;
}

const RequestsList = ({ tab }: RequestListProps) => {
    const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchGlobal = useSelector((state: RootState) => state.search.value);

    const {
        data: res,
        refetch,
        isLoading: isLoadingBookings,
        isRefetching: isRefetchingBookings,
    } = useRequestSearch({
        page,
        activeTab: getActiveTab(tab),
        searchType:
            (searchGlobal?.group_type as 'request' | 'traveller' | 'address' | 'hotel') ??
            '',
        searchValue: searchGlobal?.search_value ?? '',
    });
    const meta = res?.meta;
    const loading = isLoadingBookings || isRefetchingBookings;

    const {
        data: searchOptions,
        refetch: refetchSearch,
        isLoading,
    } = useSearch(inputValue);

    const options = useMemo(() => {
        const allOptions = [
            searchOptions?.traveller ?? [],
            searchOptions?.address ?? [],
            searchOptions?.request ?? [],
            searchOptions?.hotel ?? [],
        ];
        return allOptions?.flat();
    }, [searchOptions]);

    useEffect(() => {
        if (inputValue !== '') refetchSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchGlobal, page]);

    useEffect(() => {
        return logAmplitudeEvent(SCREEN_REQUESTS_LIST);
    }, []);

    useEffect(() => {
        logAmplitudeRequestTab(tab);
    }, [tab]);

    return (
        <>
            <PageTitle title="Requests" />
            <Autocomplete
                label="Search by request, traveller or location"
                includeInputInList
                options={options ?? []}
                groupBy={(option: SearchOption) =>
                    toUpperCaseFirstLetter(option.group_type)
                }
                getOptionLabel={option =>
                    option
                        ? option.main_display_value +
                          (option.secondary_display_value
                              ? `, ${option.secondary_display_value}`
                              : '')
                        : ''
                }
                renderOption={(prop, option) => (
                    <Box component="li" {...prop}>
                        <span>{option.main_display_value}</span>
                        {option.secondary_display_value && (
                            <span className={classes.secondaryText}>
                                &nbsp;{option.secondary_display_value}
                            </span>
                        )}
                    </Box>
                )}
                value={searchGlobal}
                onChange={(_, newValue) => {
                    dispatch(setSearch(newValue));
                    logAmplitudeEvent(INPUT_REQUESTS_SEARCH_BY_REQUEST);
                }}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                className={classes.searchBox}
                loading={isLoading}
            />
            <Grid container>
                <Grid item xs={12} marginBottom={4}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={meta?.total_pending ?? 0}
                            label="Pending"
                            url={REQUESTS_PENDING}
                            index={RequestTab.Pending}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={meta?.total_upcoming ?? 0}
                            label="Upcoming"
                            url={REQUESTS_UPCOMING}
                            index={RequestTab.Upcoming}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={meta?.total_in_progress ?? 0}
                            label="In progress"
                            url={REQUESTS_IN_PROGRESS}
                            index={RequestTab.InProgress}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={meta?.total_archived ?? 0}
                            label="Archive"
                            url={REQUESTS_ARCHIVE}
                            index={RequestTab.Archive}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                    </NumberedTabs>
                </Grid>
            </Grid>
            <TabPanel value={RequestTab.Pending} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                />
            </TabPanel>
            <TabPanel value={RequestTab.InProgress} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                />
            </TabPanel>
            <TabPanel value={RequestTab.Upcoming} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                />
            </TabPanel>
            <TabPanel value={RequestTab.Archive} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                />
            </TabPanel>
        </>
    );
};

export default RequestsList;
