import { FunctionComponent, ReactNode, useState, PropsWithChildren } from 'react';
import { NavigationBarFooter, NavigationBarLayout } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorBoundary } from 'react-error-boundary';

import { BreadcrumbHeadNode } from '../../createRoutes';
import getValue from '../../helpers/getValue';
import { Environment, getCurrentEnvironment } from '../../helpers/onEnvironment';
import Breadcrumbs from '../Breadcrumbs';
import NavigationList from './NavigationList';
import ErrorBoundaryPage from '../../pages/errorBoundaryPage/ErrorBoundaryPage';
import UserMenuButton from './UserMenuButton';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles({ userMenuButton: { marginLeft: '.25rem' } });

const Page: FunctionComponent<
    PropsWithChildren<{ breadcrumbs: BreadcrumbHeadNode; children: ReactNode }>
> = ({ children, breadcrumbs }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [menuClosed, setMenuClosed] = useState(isMobile ? true : false);
    const classes = useStyles();

    const navigationList = (() => <NavigationList />)();

    return (
        <NavigationBarLayout
            closed={menuClosed}
            onMenuClose={() => setMenuClosed(true)}
            onMenuOpen={() => setMenuClosed(false)}
            navigationList={navigationList}
            breadcrumbs={<Breadcrumbs node={breadcrumbs} />}
            environmentBadge={getValue(() => {
                switch (getCurrentEnvironment()) {
                    case Environment.Local:
                        return 'Local';
                    case Environment.Staging:
                        return 'Staging';
                    case Environment.Production:
                        return undefined;
                    default:
                        throw new Error('Unhandled environment');
                }
            })}
            actions={<UserMenuButton className={classes.userMenuButton} />}
            navigationBarFooter={<NavigationBarFooter closed={menuClosed} />}
            isLogoutMobileVisible
        >
            <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
                {children}
            </ErrorBoundary>
        </NavigationBarLayout>
    );
};

export default Page;
