import { useQuery } from 'react-query';
import { listCustomerRoles } from '../../../services/customer';

import { CUSTOMER_ROLES } from '../../../services/routes';

export function useCustomerRoles(id: number) {
    const { data: customerRoles, isLoading, isError, isFetching } = useQuery([CUSTOMER_ROLES], () => listCustomerRoles(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { customerRoles, isLoading, isError, isFetching };
}