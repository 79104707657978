import {
    NumberedTab,
    NumberedTabs,
    PrimaryButton,
    TabPanel,
} from '@get-e/react-components';
import { Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

import ActivePanel from './activePanel/ActivePanel';
import DeactivatedPanel from './deactivatedPanel/DeactivatedPanel';
import { PeopleTabs } from '../index';
import InvitedPanel from './invitedPanel/InvitedPanel';
import InviteModal from './inviteModal/InviteModal';
import {
    PEOPLE_ACTIVE,
    PEOPLE_DEACTIVATED,
    PEOPLE_INVITED,
} from '../../../constans/urlPaths';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { logAmplitudeUsersTab } from '../../../helpers/logAmplitudeTab';
import { BUTTON_INVITE_USER } from '../../../amplitude/amplKeys';

interface PeoplePageProps {
    tab: PeopleTabs;
}

const PeopleContent = ({ tab }: PeoplePageProps) => {
    const [totalActive, setTotalActive] = useState(0);
    const [totalInvited, setTotalInvited] = useState(0);
    const [totalDeactivated, setTotalDeactivated] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const { id = '' } = useParams();

    const customerId = id && parseInt(id);

    useEffect(() => {
        logAmplitudeUsersTab(tab);
    }, [tab]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={10} lg={10} xl={10}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={totalActive}
                            label="Active"
                            url={
                                customerId
                                    ? `${PEOPLE_ACTIVE}/${customerId}`
                                    : PEOPLE_ACTIVE
                            }
                            index={PeopleTabs.Active}
                            selectedIndex={tab}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={totalInvited}
                            label="Invited"
                            url={
                                customerId
                                    ? `${PEOPLE_INVITED}/${customerId}`
                                    : PEOPLE_INVITED
                            }
                            index={PeopleTabs.Invited}
                            selectedIndex={tab}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={totalDeactivated}
                            label="Deactivated"
                            index={PeopleTabs.Deactivated}
                            selectedIndex={tab}
                            url={
                                customerId
                                    ? `${PEOPLE_DEACTIVATED}/${customerId}`
                                    : PEOPLE_DEACTIVATED
                            }
                            isHeighterNumberHidden
                        />
                    </NumberedTabs>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    xl={2}
                    sx={[{ textAlign: 'right' }, isMobile && { paddingTop: '2rem' }]}
                >
                    <PrimaryButton
                        onClick={() => {
                            setIsOpenModal(true);
                            logAmplitudeEvent(BUTTON_INVITE_USER);
                        }}
                        icon={<AddIcon />}
                    >
                        Invite
                    </PrimaryButton>
                </Grid>
            </Grid>
            <TabPanel value={PeopleTabs.Active} selectedValue={tab}>
                <ActivePanel
                    isActive={tab === PeopleTabs.Active}
                    onUpdate={(active: number) => setTotalActive(active)}
                />
            </TabPanel>
            <TabPanel value={PeopleTabs.Invited} selectedValue={tab}>
                <InvitedPanel
                    isActive={tab === PeopleTabs.Invited}
                    onUpdate={(invited: number) => setTotalInvited(invited)}
                    inviteRefetch={isOpenModal}
                />
            </TabPanel>
            <TabPanel value={PeopleTabs.Deactivated} selectedValue={tab}>
                <DeactivatedPanel
                    isActive={tab === PeopleTabs.Deactivated}
                    onUpdate={(deactivated: number) => setTotalDeactivated(deactivated)}
                />
            </TabPanel>
            {isOpenModal && (
                <InviteModal
                    isOpen={isOpenModal}
                    onClose={() => {
                        setIsOpenModal(false);
                    }}
                />
            )}
        </>
    );
};

export default PeopleContent;
