import { Validated } from '../Validator';

const isEqualString = <TError>(
    value: string,
    valueToCompare: string,
    error: TError,
): Validated<string, TError> =>
    value.trim() !== valueToCompare.trim()
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };

export default isEqualString;
