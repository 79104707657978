import { useEffect } from 'react';
import { DataGrid } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { getColumns } from '../gridData';
import { useDataGridStyles } from '../styles/useDataGridStyles';
import { useAuth } from '../../../../context/AuthenticatedUserContext';
import { emptyAirline } from '../../../../images';
import { EDIT_HOTEL_USER } from '../../../../constans/urlPaths';
import { LOCAL_STORAGE_KEYS } from '../../../../constans/localStorageKeys';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { useActiveHotelUsers } from '../../api/useActiveHotelUsers';
import { mapUserToTableRow } from '../../helpers/tableMapper';
import { BUTTON_EDIT_USER } from '../../../../amplitude/amplKeys';

interface ActiveProps {
    onUpdate: (active: number) => void;
    isActive: boolean;
}

const ActivePanel = ({ onUpdate, isActive }: ActiveProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dataGridClasses = useDataGridStyles();
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { user } = useAuth();

    const hotelId = (id && parseInt(id)) || (user?.hotel.id ?? -1);

    const { activeUsersData = [], isFetching: isFetchingActive } = useActiveHotelUsers(hotelId);

    useEffect(() => {
        onUpdate?.(activeUsersData.length ?? 0);
    }, [activeUsersData.length, onUpdate]);

    const rows = activeUsersData.length ? mapUserToTableRow(activeUsersData) : [];

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_EDITED,
            params.row.name ?? '',
        );
        navigate(`${EDIT_HOTEL_USER}${params.id}`);
        logAmplitudeEvent(BUTTON_EDIT_USER)
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <>
                    <DataGrid
                        className={clsx(
                            dataGridClasses.dataGrid,
                            !rows.length ? dataGridClasses.dataGridNoRows : '',
                        )}
                        rows={isFetchingActive ? [] : rows}
                        columns={getColumns()}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        autoHeight
                        noRows="No result found"
                        loading={isFetchingActive}
                        getRowHeight={() => (isMobile ? 'auto' : null)}
                        noResultImage={emptyAirline}
                        onRowClick={handleRowClick}
                        noRowsClassName={dataGridClasses.noResultsWrapper}
                        hideFooter={!rows.length}
                    />
                </>
            )}
        </div>
    );
};

export default ActivePanel;
