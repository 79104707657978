import { Entity, StatementVariables } from "../pages/billing/api/types";
import apiClient from "./api";
import { getRelationNamesRoute, STATEMENTS } from "./routes";

export const listStatements = async (
  variables: StatementVariables
) => {
    const searchParams = new URLSearchParams({
        searchValue: variables?.search || '',
        customerId: variables?.customerId?.toString() || '',
        hotelId: variables?.hotelId?.toString() || '',
        fromDateLocal: variables?.fromDateLocal || '',
        toDateLocal: variables?.toDateLocal || '',
        limit: variables?.limit?.toString() || '',
        page: ((variables?.page ?? 0 )+ 1)?.toString() || '',
    }).toString();

    const { data } = await apiClient.get(`${STATEMENTS}?${searchParams}`);

    return data;
};

export const listRelationNames = async (hotelId: number = 0, search: string, limit?: number): Promise<Entity[]> => {
  const searchParams = new URLSearchParams({
    searchValue: search || '',
    limit: limit?.toString() || '',
}).toString();
  const response = await apiClient.get(`${getRelationNamesRoute(hotelId)}?${searchParams}`);

  return response?.data as Entity[];
};