import { ReactElement } from 'react';
import { useIsBackoffice, useRoles } from '../context/AuthenticatedUserContext';
import { StringMap } from '../helpers/types/StringMap';

import { PERMISSIONS } from './permissionMaps';

const hasPermission = ({
    permissions,
    scopes,
}: {
    permissions: string[];
    scopes?: string[];
}) => {
    const scopesMap: StringMap = {};
    scopes?.forEach(scope => {
        scopesMap[scope] = true;
    });
    return permissions.some(permission => scopesMap[permission]);
};

interface PermissionsGateProps {
    children: ReactElement;
    scopes?: string[];
}

const PermissionsGate = ({ children, scopes = [] }: PermissionsGateProps) => {
    const roles = useRoles();
    const isBackoffice = useIsBackoffice();

    if (isBackoffice) return <>{children}</>;

    const permissions: string[] = [];

    roles.map((role: string) => {
        permissions.push(...PERMISSIONS[role]);
    });
    
    const permissionGranted = hasPermission({ permissions, scopes });

    if (!permissionGranted) return <></>;
    return <>{children}</>;
};

export default PermissionsGate;
