import apiClient from './api';
import { User } from '../pages/people/api/types';
import {
    getActiveAndDeactivatedRoute,
    getInvitedPeopleRoute,
    getRevokeInvitationRoute,
} from './routes';

export const listInvitedPeople = async (id: number): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(getInvitedPeopleRoute(id));

    return data;
};

export const listPeople = async (id: number): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(
        getActiveAndDeactivatedRoute(id),
    );

    return data;
};

export const revokeInvitedUser = async (customerId: number, userInviteId: number) => {
    const { data } = await apiClient.delete(
        getRevokeInvitationRoute(customerId, userInviteId),
    );

    return data;
};
