import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../constans/colors';

export const useDataGridStyles = makeStyles({
    headerTitle: {
        fontWeight: '600',
    },
    scrollContent: {
        maxHeight: 1000,
    },
    root: {
        border: 'none',
        borderRadius: '0px 0px 4px 4px',
    },
    dataGrid: {
        marginTop: '2rem',
        position: 'relative',
        '& .dataGridRole': {
            color: COLORS.BLACK_50,
            width: '100%',
            display: 'flex',

            '& > div': {
                display: 'flex',
                flexDirection: 'row',
                marginRight: '1.25rem',
            },
        },
        '& .dataGridRoleIcon': {
            marginRight: '5px',
        },
        '& .dataGridMobile': {
            width: '100%',
            padding: '7px 0',
        },
        '& .MuiDataGrid-cellContent': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell': {
            outline: 'none !important',
        },
    },
    hideRightSeparator: {
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
        },
    },
    revokeItem: {
        padding: '.75rem 1rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
        margin: '.5rem 0',
        cursor: 'pointer',
    },
    dataGridNoRows: {
        '& .MuiDataGrid-root': {
            minHeight: "330px !important",
        },
    },
    noResultsWrapper: {
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
        '& > img': {
            width: '470px !important',
            height: '210px !important',
            marginBottom: '0.75rem',
        }
    }
});
