export const DEAFULT_ADD_BUTTON_OPTION = {
    id: 'ADD',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    employeeNumber: '',
};

export const DEAFULT_ADD_HOTEL_BUTTON_OPTION = {
    id: 'ADD',
    name: '',
    hotelAddress: '',
    phoneNumber: '',
    email: '',
    notes: '',
};

export const NO_RESULTS_OPTION = {
    id: 'NO_RESULTS',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    employeeNumber: '',
};

export const NO_RESULTS_HOTEL_OPTION = {
    id: 'NO_RESULTS',
    name: '',
    hotelAddress: '',
    phoneNumber: '',
    email: '',
    notes: '',
};
