import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import { COLORS } from "../constans/colors";

export const dateTimeRangePickerStyles = {
  width: '100%',
};

export const textFieldStyles = {
  variant: "filled", 
  InputProps: { 
    endAdornment: <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY, fontSize: '1.2rem' }} />
  }
};

export const dayStyles = {
  "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
    backgroundColor: "rgba(25, 118, 210, 0.12)",
  },
  "& .MuiDateRangePickerDay-rangeIntervalDayPreview": {
    borderRadius: '50%',
    borderStyle: 'dashed',
    borderColor: "rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12)",
    transition: 'none',
  },
  "& .MuiDateRangePickerDay-dayOutsideRangeInterval": {
    ":hover": {
      border: "1px solid rgb(158, 158, 158)",
    }
  },
  '& .MuiDateRangePickerDay-dayOutsideRangeInterval:hover': {
    border: '1px solid rgb(158, 158, 158)',
  },
};
