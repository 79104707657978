import { useQuery } from 'react-query';

import { SEARCH_REQUESTS } from '../../../constans/queryKeys';
import { searchRequests } from '../../../services/request';

export const useSearch = (search: string) => {
    return useQuery([SEARCH_REQUESTS + search], () => searchRequests(search), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
        enabled: search !== '',
    });
};
