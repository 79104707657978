import { Valid, Validated } from '../Validator';

export default function or<
    TFirstValue,
    TSecondValue,
    TSecondError,
    >(
        firstResult: Validated<TFirstValue, unknown>,
        createSecond: () => Validated<TSecondValue, TSecondError>
    ): Valid<TFirstValue> | Validated<TSecondValue, TSecondError> {
    return firstResult.isValid
        ? firstResult
        : createSecond();
}
