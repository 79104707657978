export const getCurrentUserCountryCode = () => {
    const { countries, zones } = require('moment-timezone/data/meta/latest.json');
    const timeZoneCityToCountry: any = {};

    Object.keys(zones).forEach(z => {
        const cityArr = z.split('/');
        const city = cityArr[cityArr.length - 1];
        timeZoneCityToCountry[city] = countries[zones[z].countries[0]];
    });

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    const userCity = tzArr[tzArr.length - 1];

    return timeZoneCityToCountry[userCity]?.abbr;
}