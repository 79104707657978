import apiClient from './api';
import {
    AcceptUserInviteRequest,
    ForgotPasswordRequest,
    InviteUserRequest,
    ResetPasswordRequest,
    User,
    UserRequest,
} from '../pages/people/api/types';
import { ACCEPT_USER_INVITE, FORGOT_PASSWORD, INVITE_HOTEL_USER, INVITE_USER, RESET_PASSWORD, USERS } from './routes';

export const inviteUser = async (request: InviteUserRequest) => {
    const response = await apiClient.post<InviteUserRequest>(INVITE_USER, request);

    return response;
};

export const inviteHotelUser = async (request: InviteUserRequest) => {
    const response = await apiClient.post<InviteUserRequest>(INVITE_HOTEL_USER, request);

    return response;
};

export const acceptUserInvite = async (
    request: AcceptUserInviteRequest,
): Promise<any> => {
    const response = await apiClient.post<AcceptUserInviteRequest>(
        ACCEPT_USER_INVITE,
        request,
    );

    return response;
};

export const viewUser = async (id: number): Promise<User> => {
    const { data } = await apiClient.get<User>(`${USERS}/${id}`);

    return data;
};

export const updateUser = async (variables: UserRequest): Promise<User> => {
    const { id, firstName, lastName, isActive, roleIds, notificationSettings } = variables;
    const { data } = await apiClient.put(`${USERS}/${id}`, {
        firstName,
        lastName,
        isActive,
        roleIds,
        notificationSettings
    });

    return data;
};

export const forgotPassword = async (
    request: ForgotPasswordRequest,
): Promise<any> => {
    const response = await apiClient.post<ForgotPasswordRequest>(
        FORGOT_PASSWORD,
        request,
    );

    return response;
};

export const resetPassword = async (
    request: ResetPasswordRequest,
): Promise<any> => {
    const response = await apiClient.post<ResetPasswordRequest>(
        RESET_PASSWORD,
        request,
    );

    return response;
};
