import { useQuery } from 'react-query';

import { HOTEL } from '../../../constans/queryKeys';
import { BookingVariables } from './types';
import { listBookings } from '../../../services/booking';
import { BOOKINGS } from '../../../constans/urlPaths';

export const useBookingSearch = (variables: Partial<BookingVariables>) => {
    const { page = 1, customerId, activeTab, searchType, searchValue, startDate, endDate } = variables;

    return useQuery(
        [(activeTab ? activeTab + page : HOTEL + page) + BOOKINGS +  startDate + endDate + searchType + searchValue ],
        () =>
            listBookings({
                page,
                customerId,
                activeTab,
                searchType,
                searchValue,
                startDate,
                endDate
            }),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            cacheTime: 500,
        },
    );
};
