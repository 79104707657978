import { AxiosResponse } from "axios";

import apiClient from "./api";
import { getInvoiceRoute, INVOICES } from "./routes";
import { UploadInvoiceRequest } from "../pages/invoices/uploadInvoice/api/types";
import { InvoiceVariables } from "../pages/invoices/types";

export const createInvoice = async (
    variables: UploadInvoiceRequest,
): Promise<AxiosResponse> => {
    const { files, ...rest } = variables;
    const formData = new FormData();

    Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });

    const response = await apiClient.post(INVOICES, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
};

export const listInvoices = async (
    variables: InvoiceVariables
  ) => {
      const searchParams = new URLSearchParams({
          searchValue: variables?.search || '',
          customerId: variables?.customerId?.toString() || '',
          hotelId: variables?.hotelId?.toString() || '',
          fromDateLocal: variables?.fromDateLocal || '',
          toDateLocal: variables?.toDateLocal || '',
          limit: variables?.limit?.toString() || '',
          page: ((variables?.page ?? 0 )+ 1)?.toString() || '',
          status: variables?.status
      }).toString();
  
      const { data } = await apiClient.get(`${INVOICES}?${searchParams}`);
  
      return data;
  };

  export const updateInvoice = async (
    variables: UploadInvoiceRequest
): Promise<AxiosResponse> => {
    const { files, id, ...rest } = variables;
    const formData = new FormData();
    formData.append( '_method', 'PUT')

    Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });

    const response = await apiClient.post(getInvoiceRoute(id ?? ''), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
};