import { MenuButton, MenuButtonItem, userButtonIcon } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SIGN_IN } from '../../constans/urlPaths';
import { useAuth } from '../../context/AuthenticatedUserContext';

const useStyles = makeStyles({
    button: { padding: '.5rem 1rem !important' },
    icon: { margin: '0 0 0 .5em' },
});

interface UserMenuButtonProps {
    className?: string;
}

const UserMenuButton = ({ className }: UserMenuButtonProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const { user, onLogout } = useAuth();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [signingOut, setSigningOut] = useState(false);

    const userName = useMemo(
        () => (user ? `${user?.firstName} ${user?.lastName}` : 'Username'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.firstName, user?.lastName],
    );

    const signOutFailed = () => {
        setSigningOut(false);
        setMenuOpen(false);
    };

    const signOut = async () => {
        setSigningOut(true);
        try {
            await onLogout();
            navigate(SIGN_IN);
        } catch (error) {
            signOutFailed();
        }
    };

    return (
        <span className={className}>
            <MenuButton
                className={classes.button}
                open={menuOpen}
                onClick={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                menuItems={[
                    <MenuButtonItem
                        key="sign-out"
                        loading={signingOut}
                        onClick={signOut}
                        dangerous
                    >
                        Sign out
                    </MenuButtonItem>,
                ]}
            >
                {!isMobile && userName}
                <img src={userButtonIcon} alt="User icon" className={classes.icon} />
            </MenuButton>
        </span>
    );
};

export default UserMenuButton;
