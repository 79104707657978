import { useQuery } from 'react-query';

import { ACTIVE_HOTEL_USERS } from '../../../constans/queryKeys';
import { listUsers } from '../../../services/hotelUsers';

export function useActiveHotelUsers(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [ACTIVE_HOTEL_USERS],
        () => listUsers(id),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: 1,
        },
    );

    const activeUsersData = data?.filter(el => el.isActive);

    return { activeUsersData, isLoading, isError, isFetching, refetch };
}
