import GoogleMapReact from 'google-map-react';
import { Moment } from 'moment';
import { FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PlaceIcon from '@mui/icons-material/Place';

import { bootstrapURLKeys } from '../helpers/maps/googleApi';

export interface Point {
    longitude: number;
    latitude: number;
}

export type DriverPosition = null | {
    timestamp: Moment;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    coordinatesAccuracyMeters: number | null;
    bearingDegrees: number | null;
};

interface MarkerProps {
    lat: number;
    lng: number;
}

const Marker = ({ lat, lng }: MarkerProps) => (
    <PlaceIcon
        color="error"
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        }}
    />
);

const DEFAULT_MAP_ZOOM = 11;

const GoogleMap: FunctionComponent<{
    points: Point[];
    driverPosition?: DriverPosition;
    onLoaded?: () => void;
    zoom?: number;
}> = ({ points, zoom = DEFAULT_MAP_ZOOM }) => {
    const pickupCoordinates = points[0];

    return (
        <ErrorBoundary fallback={<>Error</>}>
            <GoogleMapReact
                bootstrapURLKeys={bootstrapURLKeys}
                center={{
                    lat: pickupCoordinates.latitude,
                    lng: pickupCoordinates.longitude,
                }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
            >
                <Marker
                    lat={pickupCoordinates.latitude}
                    lng={pickupCoordinates.longitude}
                />
            </GoogleMapReact>
        </ErrorBoundary>
    );
};

export default GoogleMap;
