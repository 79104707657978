import { useQuery } from 'react-query';

import { REQUEST } from '../../../constans/queryKeys';
import { viewRequest } from '../../../services/request';

export function useRequest(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [REQUEST],
        () => viewRequest(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
        },
    );

    return { data, isLoading, isError, isFetching, refetch };
}
