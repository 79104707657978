import makeStyles from '@mui/styles/makeStyles';
import { Typography, useMediaQuery } from '@mui/material';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';

import StatusButton from '../StatusButton';
import { getCheckDate, getDifferenceInDays } from '../../../../helpers/dateUtils';
import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles({
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    userIcon: {
        marginRight: '1.2rem',
    },
    headingDetails: {
        display: 'flex',
        alignItems: 'center',
    },
    headingDetailsMobile: {
        alignItems: 'flex-start',
        marginTop: '.5rem',
    },
    headingButton: {
        marginLeft: '1rem',
    },
    headingButtonMobile: { marginLeft: '0' },
    hotel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '500px',
        marginTop: '1rem',
        alignItems: 'flex-start',
    },
    hotelMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    hotelIcon: {
        display: 'flex',
        flexDirection: 'row',
    },
});

interface BookingHotelProps {
    checkInDate: string;
    checkOutDate: string;
    hotelName: string;
    bookingNumber: string;
    status: string;
}

const BookingHotel = ({
    checkInDate,
    checkOutDate,
    hotelName,
    bookingNumber,
    status,
}: BookingHotelProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className={isMobile ? classes.hotelMobile : classes.hotel}>
            <div className={classes.hotelIcon}>
                <HotelOutlinedIcon fontSize="small" className={classes.userIcon} />
                <div>
                    <Typography variant="body1">{hotelName}</Typography>
                    <Typography variant="caption" className={classes.secondaryText}>
                        {getCheckDate(checkInDate)} - {getCheckDate(checkOutDate)}
                        &nbsp;({getDifferenceInDays(checkOutDate, checkInDate)} nights)
                    </Typography>
                </div>
            </div>
            <div
                className={
                    isMobile ? classes.headingDetailsMobile : classes.headingDetails
                }
            >
                <Typography variant="body1">{bookingNumber}</Typography>
                <StatusButton
                    className={
                        isMobile ? classes.headingButtonMobile : classes.headingButton
                    }
                    variant={status}
                >
                    {status}
                </StatusButton>
            </div>
        </div>
    );
};

export default BookingHotel;
