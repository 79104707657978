import { Validated } from '../Validator';

const isFilledObjectAttributes = <TValue, TError>(
    value: TValue | null,
    error: TError,
): Validated<TValue, TError> =>
    value === null || Object.values(value).every(x => x === null || x === '')
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };

export default isFilledObjectAttributes;
