import { TFunction } from 'i18next';
import report from '../report';
import FormError from './FormError';

export default function getFormErrorMessage(error: FormError, t: TFunction): string {
    switch (error) {
        case FormError.Unauthorized:
            return t('errors.forms.unauthorized');
        case FormError.UnexpectedError:
            return t('errors.forms.unexpected');
        case FormError.UserError:
            return t('errors.forms.userError');
        default:
            report(new Error('Unhandled form error'));
            return t('errors.forms.unexpected');
    }
}
