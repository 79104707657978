import { useQuery } from 'react-query';

import { DEACTIVATED_PEOPLE } from '../../../constans/queryKeys';
import { listPeople } from '../../../services/people';

export function useDeactivated(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [DEACTIVATED_PEOPLE],
        () => listPeople(id),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: 1,
        },
    );

    const deactivatedPeopleData = data?.filter(el => !el.isActive);

    return { deactivatedPeopleData, isLoading, isError, isFetching, refetch };
}
