import { AddressRequest } from '../accounts/api/types';

export enum PlaceType {
    STREET_NUMBER = 'street_number',
    STREET_NAME = 'route',
    CITY_NEIGHBORHOOD = 'neighborhood',
    CITY_LOCALITY = 'locality',
    STATE_PROVANCE = 'administrative_area_level_1',
    POSTAL_CODE = 'postal_code',
    COUNTRY = 'country',
}

export function mapPlaceToAddress(place: any) {
    const address = {} as AddressRequest;
    let streetName = '';
    let streetNumber = '';
    let postalCode = '';

    place.address_components.forEach(function (selectedPlace: any) {
        switch (selectedPlace.types[0]) {
            case PlaceType.STREET_NUMBER:
                streetNumber = selectedPlace?.long_name;
                break;
            case PlaceType.STREET_NAME:
                streetName = selectedPlace?.long_name;
                break;
            case PlaceType.CITY_NEIGHBORHOOD:
            case PlaceType.CITY_LOCALITY:
                address.city = selectedPlace?.long_name;
                break;
            case PlaceType.STATE_PROVANCE:
                address.state_province = selectedPlace?.long_name;
                break;
            case PlaceType.POSTAL_CODE:
                postalCode = selectedPlace?.long_name;
                break;
            case PlaceType.COUNTRY:
                address.country_code = selectedPlace?.short_name;
                break;
        }
    });

    const hasStreetNumber = ' ' + streetNumber ?? '';
    address.address_line = streetName + hasStreetNumber;
    address.postal_code = postalCode;
    address.lat = place.geometry.location.lat().toString();
    address.long = place.geometry.location.lng().toString();
    address.name = place.name;
    address.city = address.city ? address.city : '';
    return address;
}
