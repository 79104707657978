import { useQuery } from 'react-query';

import { DEACTIVATED_HOTEL_USERS } from '../../../constans/queryKeys';
import { listUsers } from '../../../services/hotelUsers';

export function useDeactivated(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [DEACTIVATED_HOTEL_USERS],
        () => listUsers(id),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: 1,
        },
    );

    const deactivatedUsersData = data?.filter(el => !el.isActive);

    return { deactivatedUsersData, isLoading, isError, isFetching, refetch };
}
