import { AxiosResponse } from "axios";

import apiClient from "../../../services/api";
import { getInvoiceRoute, getDeleteFileFromInvoiceRoute } from "../../../services/routes";

export const deleteInvoice = async (id: number): Promise<AxiosResponse> => {
    const response = await apiClient.delete(getInvoiceRoute(id));

    return response;
};

export const deleteFileFromInvoice = async ({id, fileId}: {id: number; fileId: number}): Promise<AxiosResponse> => {
    const response = await apiClient.delete(getDeleteFileFromInvoiceRoute(id, fileId));

    return response;
};