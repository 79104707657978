import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FinanceTransaction } from '../../../hotels/api/types';
import { COLORS } from '../../../../constans/colors';
import { calculateBookingPrice, calculateCommissionPrice, calculateFeePrice } from '../../../../helpers/finance';
import { Grid, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import PercentIcon from '@mui/icons-material/Percent';

interface Props {
    transactions: FinanceTransaction[]|undefined
}

const TransactionTable = ({transactions}: Props) => {

    if(transactions === undefined || transactions.length < 1) {
        return(
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
               <TableBody>
                    <TableRow style={{backgroundColor: '#f2f4f5'}}>
                        <TableCell>No transactions found!</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    let currency: string | undefined = transactions.find(transaction => transaction.type === 'REVENUE')?.currency;
    let commission = calculateCommissionPrice(transactions);
    let fee = calculateFeePrice(transactions);


    return (
        <div>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow style={{backgroundColor: '#f2f4f5'}}>
                    <TableCell>Description</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Amount ({currency})</TableCell>
                    <TableCell align="left">Units</TableCell>
                    <TableCell align="left">Total ({currency})</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {transactions.map((transaction: FinanceTransaction) => (
                    <TableRow
                    key={transaction.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row" style={{ color: transaction.amount < 0 ? COLORS.RED_WARNING : COLORS.GREEN }}>
                        {transaction.comment}
                    </TableCell>
                    <TableCell align="left" style={{ color: transaction.amount < 0 ? COLORS.RED_WARNING : COLORS.GREEN }}>{transaction.type}</TableCell>
                    <TableCell align="left" style={{ color: transaction.amount < 0 ? COLORS.RED_WARNING : COLORS.GREEN }}>{transaction.amount}</TableCell>
                    <TableCell align="left" style={{ color: transaction.amount < 0 ? COLORS.RED_WARNING : COLORS.GREEN }}>{transaction.units}</TableCell>
                    <TableCell align="left" style={{ color: transaction.amount < 0 ? COLORS.RED_WARNING : COLORS.GREEN }}>{transaction.amount * transaction.units}</TableCell>

                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '1rem' }}

            >
                <Grid item xs={12}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                        <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <PaidIcon fontSize="small" />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                        >
                            <Typography
                                sx={{ fontSize: '1.1rem' }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Total Cost: {calculateBookingPrice(transactions)}
                            </Typography>
                        </Grid>
                        {commission && <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <PercentIcon fontSize="small" />
                        </Grid>}
                        {commission && <Grid
                            item
                            xs={5}
                            sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                        >
                            <Typography
                                sx={{ fontSize: '1.1rem' }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Total Commission: {commission}
                            </Typography>
                        </Grid>}

                        {fee && <Grid
                            item
                            xs={5}
                            sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                        >
                            <Typography
                                sx={{ fontSize: '1.1rem' }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Total Fee: {fee}
                            </Typography>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TransactionTable;
