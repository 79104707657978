import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { StatusOption } from "../types";
import { COLORS } from "../../../constans/colors";

const useStyles = makeStyles(() => ({
    green: {  
        color: COLORS.GREEN,
        pointerEvents: 'none',
        fontSize: '12px',
        padding: '4px'
    },
    yellow: {
        color: COLORS.ORANGE_WARNING,
        pointerEvents: 'auto',
        fontSize: '12px',
        padding: '4px'
    }
}));


export interface StatusButtonProps {
    status: StatusOption;
}

export const StatusButton = ({status} : StatusButtonProps) => {
    const classes = useStyles();

    return (
        <Button variant="outlined" className={status === StatusOption.MATCHED ? classes.green : classes.yellow} color="inherit">
            {status === StatusOption.MATCHED ? "Matched" : "No matched"}
        </Button>

    )
}