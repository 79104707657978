
import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment';

export interface DateRangeState {
  value: DateRange<Moment>
}

const now = new Date();

const initialState: DateRangeState = {
  value: [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())), moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30, 23, 59, 0, 0))]
}

export const dateSlice = createSlice({
  name: 'dateRangeFilter',
  initialState,
  reducers: {
    setDateRangeFilter: (state: any, action: PayloadAction<DateRange<Moment>>) => {
      state.value = action.payload
    },
  },
})

export const { setDateRangeFilter } = dateSlice.actions

export default dateSlice.reducer